// import './ServicePage.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/payment-methode.png";
import img1 from "../assets/images/p1.png";
import img2 from "../assets/images/p2.png";
import img3 from "../assets/images/p3.png";
import img4 from "../assets/images/p4.png";
import img5 from "../assets/images/p5.png";
import img6 from "../assets/images/p6.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./Logistics.css";
const PaymentMethod = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-2 pb-2">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-9">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4 text-center">{t("payment_method_page")}</h2>
              <div className="row g-4 mb-3 justify-content-center">
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/bank-detail"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img1}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("bank_account_details")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/letter-of-credit"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img2}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("letter_of_credit")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link to="/cash" className="text-decoration-none text-dark">
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img4}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("cash")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/rules"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img5}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("6040rule")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/crypto-currency"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img6}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("cryptocurrency")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card rounded-15 border-0">
                    <Link
                      to="/terms-and-condition"
                      className="text-decoration-none text-dark"
                    >
                      <div className="card-body p-0">
                        <div className="imgCol position-relative">
                          <img
                            src={img3}
                            className="img-fluid rounded-10"
                            alt="Service 6"
                          />
                          <span className="short-btn rounded-pill bg-white position-absolute rounded-pill bg-white position-absolute p-2 ps-3 pe-3">
                            {t("trms_condtn")}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="container-text">
                {/* <!-- Container for the fade effect --> */}
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Payment Methods Section --> */}
                        <div class="col-12 pb-3">
                          {/* <h3 class="fw-bold">Payment Methods</h3> */}
                          <p>
                            We offer a variety of flexible payment options to
                            make your vehicle purchase convenient and secure.
                            Choose the method that works best for you, and rest
                            assured that every transaction is handled with the
                            utmost professionalism and transparency. Explore our
                            available payment methods below:
                          </p>
                          <p>
                            <strong>1. Bank Account Details.</strong> For
                            traditional payments, we provide secure bank account
                            details for easy wire transfers.
                          </p>
                          <p>
                            <strong>2. Letter of Credit.</strong> We accept
                            payments via letter of credit, ensuring a safe and
                            trusted way to process large transactions.
                          </p>
                          <p>
                            <strong>3. Cash Payments.</strong> Prefer cash? We
                            accept cash payments in approved currencies at
                            designated locations.
                          </p>
                          <p>
                            <strong>4. USDT & Cryptocurrency.</strong> For the
                            convenience of modern digital transactions, we
                            accept payments in USDT and other cryptocurrencies.
                            Fast, secure, and global.
                          </p>
                          <p>
                            <em>Note:</em> Read through the specific terms and
                            conditions related to all payment methods before
                            proceeding with your transaction.
                          </p>
                          <p>
                            Click on any option above to learn more and choose
                            the best payment method for your purchase.
                          </p>
                        </div>
                        <Link className="theme-color" to="/terms-and-condition">
                {t("trms_condtn")}{" "}
              </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentMethod;
