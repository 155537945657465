import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
// import type { UploadProps } from 'antd';
import { Button, message, Upload } from "antd";
import banner from "../assets/images/supply-contract.png";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";
import "./Logistics.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
// const props: UploadProps = {
const props = {
  name: "file",
  action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

const SupplyContract = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    person_name: "",
    person_email: "",
    mobile: "",
    person_designation: "",
    company_address: "",
    comments: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: null,
    vehicleSpecs: null,
    purchaseOrder: null,
    letterOfIntent: null,
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    purchaseOrder: [],
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const validate = () => {
    let formErrors = {};

    if (!formData.company_name)
      formErrors.company_name = t("errors.company_name");
    if (!formData.person_name) formErrors.person_name = t("errors.person_name");
    if (!formData.person_email)
      formErrors.person_email = t("errors.person_email_required");
    if (formData.person_email && !/\S+@\S+\.\S+/.test(formData.person_email)) {
      formErrors.person_email = t("errors.person_email_invalid");
    }
    if (!formData.mobile) formErrors.mobile = t("errors.phone_number");
    if (!formData.person_designation)
      formErrors.person_designation = t("errors.person_designation");
    if (!formData.company_address)
      formErrors.company_address = t("errors.company_address");

    // Validate file uploads
    // if (!uploadedFiles.tradeLicense)
    //   formErrors.tradeLicense = "Company Trade License is required";
    // if (!uploadedFiles.purchaseOrder)
    //   formErrors.purchaseOrder = "Purchase Order is required";

    if (fileList.tradeLicense.length === 0)
      formErrors.tradeLicense = t("errors.trade_license");
    if (fileList.purchaseOrder.length === 0)
      formErrors.purchaseOrder = t("errors.purchase_order");

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "comments" && value.length > charLimit) {
      return; // Prevent exceeding character limit
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "comments") {
      setCharCount(value.length); // Update character count for comments
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });
  };

  const beforeUpload = (file) => {
    const isAllowedFileType = allowedFileTypes.includes(file.type);

    if (!isAllowedFileType) {
      message.error(
        `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
      );
    }

    return isAllowedFileType ? false : Upload.LIST_IGNORE; // Prevent upload if file type is not allowed
  };

  const handleFileChange = ({ fileList: newFileList }, fileType) => {
    setFileList({
      ...fileList,
      [fileType]: newFileList,
    });
  };

  const handleUploadChange = (info, fileType) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: info.file,
      });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      setUploadedFiles({
        ...uploadedFiles,
        [fileType]: null,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const data = new FormData(); // Create FormData object
    data.append("company_name", formData.company_name);
    data.append("person_name", formData.person_name);
    data.append("person_email", formData.person_email);
    data.append("phone_number", formData.mobile);
    data.append("person_designation", formData.person_designation);
    data.append("company_address", formData.company_address);
    data.append("comments", formData.comments);

    let attachments = [];
    if (fileList.tradeLicense.length > 0) {
      attachments.push({
        name: "Company Trade License",
        file: fileList.tradeLicense[0].originFileObj,
      });
    }
    if (fileList.purchaseOrder.length > 0) {
      attachments.push({
        name: "Purchase Order",
        file: fileList.purchaseOrder[0].originFileObj,
      });
    }

    attachments.forEach((attachment) => {
      data.append(`attachments[][name]`, attachment.name); // Append the name of the attachment
      data.append(`attachments[][file]`, attachment.file); // Append the file of the attachment
    });
    // Append files to form data
    // if (fileList.tradeLicense.length > 0) {
    //   data.append("tradeLicense", fileList.tradeLicense[0].originFileObj);
    // }
    // if (fileList.purchaseOrder.length > 0) {
    //   data.append("purchaseOrder", fileList.purchaseOrder[0].originFileObj);
    // }

    const response = await dispatch(submitSupplyContractForm(data));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
      setRefId(response?.data?.code);
      setFormSubmitted(true);
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    if (userData) {
      // If user is a company, fill both user and company details
      if (userData.company) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: userData.company.name || "", // Fill company name
          company_address: userData.company.address || "", // Fill company address
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      } else {
        // If user is not a company, fill only personal details
        setFormData((prevData) => ({
          ...prevData,
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      }
    }

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              {formSubmitted ? (
                <SuccessForm
                  refId={refId}
                  resetForm={() => {
                    setFormSubmitted(false);
                  }}
                />
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="card rounded-10 bg-white border-0 mb-5">
                    <div className="card-body p-5">
                      <h2 className="mb-4  text-center">{t("scia")}</h2>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("company_name")}*`}
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            customClass={
                              errors.company_name ? "is-invalid" : ""
                            }
                          />
                          {errors.company_name && (
                            <div className="text-danger font-13">
                              {errors.company_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_name")}*`}
                            type="text"
                            name="person_name"
                            value={formData.person_name}
                            onChange={handleChange}
                            customClass={errors.person_name ? "is-invalid" : ""}
                          />
                          {errors.person_name && (
                            <div className="text-danger font-13">
                              {errors.person_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_email")}*`}
                            type="email"
                            name="person_email"
                            value={formData.person_email}
                            onChange={handleChange}
                            customClass={
                              errors.person_email ? "is-invalid" : ""
                            }
                          />
                          {errors.person_email && (
                            <div className="text-danger font-13">
                              {errors.person_email}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="mobile"
                              value={formData.mobile}
                              className="w-100"
                              onChange={handlePhoneChange}
                              inputClass={`form-control ${
                                errors.mobile ? "is-invalid" : ""
                              }`}
                            />
                            {errors.mobile && (
                              <div className="text-danger font-13">
                                {errors.mobile}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("person_designation")}*`}
                            type="text"
                            name="person_designation"
                            value={formData.person_designation}
                            onChange={handleChange}
                            customClass={
                              errors.person_designation ? "is-invalid" : ""
                            }
                          />
                          {errors.person_designation && (
                            <div className="text-danger font-13">
                              {errors.person_designation}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("company_address")}*`}
                            type="text"
                            name="company_address"
                            value={formData.company_address}
                            onChange={handleChange}
                            customClass={
                              errors.company_address ? "is-invalid" : ""
                            }
                          />
                          {errors.company_address && (
                            <div className="text-danger font-13">
                              {errors.company_address}
                            </div>
                          )}
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-paperclip me-2"></i>{" "}
                        <span>Attachments</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                                                    name="file"
                                                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                                                    onChange={(info) => handleUploadChange(info, 'tradeLicense')}
                                                    className="custom-upload"
                                                >
                                                    <Button icon={<UploadOutlined />}>Company Trade License *</Button>
                                                </Upload> */}
                          <Upload
                            fileList={fileList.tradeLicense}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "tradeLicense")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("cmp_trade_license")}*`}
                            </Button>
                          </Upload>
                          {errors.tradeLicense && (
                            <div className="text-danger font-13">
                              {errors.tradeLicense}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          {/* <Upload
                          name="file"
                          action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                          onChange={(info) =>
                            handleUploadChange(info, "purchaseOrder")
                          }
                          className="custom-upload"
                        >
                          <Button icon={<UploadOutlined />}>
                            Purchase Order *
                          </Button>
                        </Upload> */}
                          <Upload
                            fileList={fileList.purchaseOrder}
                            beforeUpload={beforeUpload}
                            onChange={(file) =>
                              handleFileChange(file, "purchaseOrder")
                            }
                            className="custom-upload"
                          >
                            <Button icon={<UploadOutlined />}>
                              {`${t("purchase_order")}*`}
                            </Button>
                          </Upload>
                          {errors.purchaseOrder && (
                            <div className="text-danger font-13">
                              {errors.purchaseOrder}
                            </div>
                          )}
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-chat me-2"></i>{" "}
                        <span>{t("comments")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-12">
                          <InputField
                            fieldType="textarea"
                            name="comments"
                            value={formData.comments}
                            placeholder={t("wrt_cmnts")}
                            onChange={handleChange}
                            rows={4}
                            customClass="custom-textarea-comments"
                          />
                          <small className="text-muted">
                            {charCount}/{charLimit} {t("characters")}
                          </small>
                        </div>
                      </div>
                      <div className="row g-3 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                            {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              <div class="container-text">
                {/* <!-- Container for the fade effect --> */}
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Secure Your Vehicle Supply Section --> */}
                        <div class="col-12 pb-3">
                          <h3 class="fw-bold">
                            Secure Your Vehicle Supply with Cruise Motors
                          </h3>
                          <p>
                            Looking for a reliable partner to meet your ongoing
                            vehicle needs? At Cruise Motors, we offer flexible
                            supply contracts designed to fit your business
                            requirements, whether you need a steady supply of
                            luxury vehicles, rugged SUVs, or an entire fleet of
                            compact cars. With a simple application process,
                            you’ll be on your way to securing a reliable vehicle
                            supply tailored to your specific needs.
                          </p>

                          <h4>
                            <strong>How to Obtain a Supply Contract:</strong>
                          </h4>

                          <p>
                            <strong>
                              Step 1: Fill Out the Application Form
                            </strong>
                          </p>
                          <p>
                            It all starts with a quick and easy form. Just
                            provide us with the key details about your business
                            and your vehicle requirements. Whether you need
                            monthly deliveries or a one-time bulk order, we’ll
                            craft a contract that aligns with your goals.
                          </p>

                          <p>
                            <strong>Step 2: Contract Customization</strong>
                          </p>
                          <p>
                            Once we receive your form, our team will review your
                            needs and get to work on drafting a contract that
                            fits your exact specifications. You’ll receive a
                            proposal with all the details, including vehicle
                            options, delivery schedules, pricing, and payment
                            terms.
                          </p>

                          <p>
                            <strong>Step 3: Review and Finalize</strong>
                          </p>
                          <p>
                            Take your time to review the contract proposal, and
                            feel free to discuss any modifications with us.
                            We’re committed to ensuring that the contract terms
                            work for you. Once finalized, you’ll have a solid,
                            reliable vehicle supply chain you can count on.
                          </p>

                          {/* <!-- Why Choose a Supply Contract Section --> */}
                          <h4 class="fw-bold">
                            Why Choose a Supply Contract with Cruise Motors?
                          </h4>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-car-front fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>Customizable Fleet Vehicles:</strong>{" "}
                                Explore our extensive inventory to choose and
                                customize the ideal models for your fleet.
                                Whether it's luxury sedans, robust SUVs, or
                                efficient compact cars, we have the perfect fit
                                for your company's needs.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-currency-dollar fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>
                                  Competitive Pricing and Guaranteed
                                  Availability:
                                </strong>{" "}
                                Receive a personalized quote for your chosen
                                vehicles. We guarantee consistent availability
                                and competitive pricing, ensuring your fleet is
                                equipped without delay.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-award fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>OEM Car Warranties:</strong> All
                                vehicles from Cruise Motors come with original
                                manufacturer warranties, affirming the quality
                                and reliability of your purchase.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-truck fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>
                                  Flexible Shipping and Logistics:
                                </strong>{" "}
                                Select from various shipping options—air, sea,
                                or land. Our comprehensive logistics solutions
                                cover all aspects of transportation to ensure
                                your vehicles are delivered safely and on time.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-box-seam fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>
                                  Tailored Minimum Order Quantities:
                                </strong>{" "}
                                We understand that every business has different
                                needs. Contact us to discuss and set up a
                                minimum order quantity that aligns with your
                                specific requirements.
                              </p>
                            </div>
                          </div>

                          {/* <!-- New Section --> */}
                          <h4 class="fw-bold">
                            Additional Benefits of Our Supply Contracts
                          </h4>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-credit-card fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>Versatile Payment Options:</strong>{" "}
                                Explore a variety of payment methods detailed on
                                our website. Each supply contract can be
                                customized to include your preferred payment
                                terms, enhancing convenience and financial
                                planning.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-clock-history fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>Reliable Delivery Schedules:</strong> We
                                provide precise delivery schedules, including
                                lead times and potential delays, to help you
                                manage your inventory effectively. Trust us to
                                keep you informed every step of the way.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-gear fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>Adaptable Contract Terms:</strong>{" "}
                                Cruise Motors offers flexible contract terms
                                with options for fixed-term or rolling
                                agreements, including opportunities for renewal
                                or renegotiation as your business needs evolve.
                              </p>
                            </div>
                          </div>

                          <div class="d-flex align-items-start mb-4">
                            <i class="bi bi-file-earmark-text fs-2 me-3"></i>
                            <div>
                              <p>
                                <strong>
                                  Detailed Product Specifications and
                                  Customization:
                                </strong>{" "}
                                Receive comprehensive details of each car model
                                available for customization. Our dedicated team
                                ensures that your vehicles precisely meet your
                                specifications and branding requirements.
                              </p>
                            </div>
                          </div>

                          <h4 class="fw-bold">Ready to Get Started?</h4>
                          <p>
                            Obtaining a supply contract with Cruise Motors is
                            the simplest way to ensure a steady, reliable flow
                            of vehicles for your business. Fill out the Supply
                            Contract Application Form today, and let’s start
                            crafting a contract that meets your exact
                            requirements.
                          </p>
                          <p>
                            Your vehicle supply is just a form away—don’t wait,
                            apply now and secure your fleet with Cruise Motors.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SupplyContract;
