import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/ShopHeader.css"; // You can create this CSS file for styling
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const ShopHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="shop-header mb-3 mt-3">
      <div className="shop-search-bar">
        <input type="text" placeholder={t("looking_for")} />
        <i className="bi bi-search shop-search-icon"></i>
      </div>
      <div className="shop-header-icons">
        <Link to="/wishlist" className="shop-header-icon">
          <i className="bi bi-heart"></i>
        </Link>
        <Link to="/cart" className="shop-header-icon cart-icon">
          <i className="bi bi-cart"></i>
          <span className="cart-badge">2</span>
        </Link>
        {!isMobile && <span className="cart-total">2,500 AED</span>}
      </div>
    </div>
  );
};

export default ShopHeader;
