import React from "react";
import ProductCard from "./ProductCard";
import SearchFilter from "./SearchFilter";

const ProductGrid = ({
  products,
  btnText,
  car_make_slug,
  car_model_slug,
  vin,
  interiorColor,
  exteriorColor,
  steeringProp,
}) => {
  return (
    <>
      <SearchFilter
        car_make_slug={car_make_slug}
        car_model_slug={car_model_slug}
        vin={vin}
        interiorColorFromProps={interiorColor}
        exteriorColorFromProps={exteriorColor}
        steeringProp = {steeringProp}
      />
      {products?.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <h3>No Cars Found</h3>
        </div>
      ) : (
        <div className="row g-2 g-sm-3 mb-5">
          {products?.map((product) => (
            <div className="col-12 col-sm-4 col-lg-3">
              <ProductCard productData={product} btnText={btnText} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductGrid;
