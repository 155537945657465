import React, { useState, useEffect } from "react";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer";
import BannerImg from "../assets/images/New-Banner 1.png";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import frontView from "../assets/images/toyota GR supra 2025.png";
import sideView from "../assets/images/toyota GR86 2024.png";
import backView from "../assets/images/toyota grand highlander 2024.png";
import innerSideView from "../assets/images/toyota highlander hybrid 2024.png";
import innerBackView from "../assets/images/toyota prius prime 2024.png";
import cockpitView from "../assets/images/toyota mirai 2024.png";
import serviceKitImg from "../assets/images/ss4.png";
import maintenanceKitImg from "../assets/images/Frame 894.png";
import accessoriesImg from "../assets/images/trade2.png";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import {
  signOutAction,
  getCarBySlug,
  getCarModel,
  bookCar,
} from "../redux/auth/authActions";
import { API_URL } from "../api/authApi";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap"; // Using Bootstrap modal for simplicity
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Thumbs } from "swiper/modules";
import { useTranslation } from "react-i18next";
import bannerPlaceHolder from "../assets/images/Hero-Banner-Placeholder-Light-.png";
import "../App.css";
import i18n from "../i18n";
const CarSpecification = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [car, setCar] = useState([]);
  const api_url = API_URL.replace("/api", "");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [carModel, setCarModel] = useState([]);
  const { id, name } = location.state || {};
  const searchParams = new URLSearchParams(location?.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [hoveredIdx, setHoveredIdx] = useState(null);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const slug = searchParams?.get("car");
  const fetchCarBySlug = async (slug) => {
    const response = await dispatch(getCarBySlug(slug));
    console.log("hvbhvbhb:", response?.data);
    setCar(response?.data);
    const pdfUrl = response?.data?.attachments?.[0]?.file?.path
      ? `${api_url}/${response?.data?.attachments?.[0]?.file?.path}`
      : null;

    setPdfUrl(pdfUrl);
    //for testing purpose only
    //  setPdfUrl("/prado%20spec%20sheet-compressed%201.pdf");
  };
  const downloadPdf = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const filename = pdfUrl.split("/").pop(); // Get the file name from the URL
      saveAs(blob, filename); // Save the file using 'file-saver'
    } catch (error) {
      console.error("Failed to download PDF", error);
    }
  };

  const viewOrder = [
    "Front View",
    "Side View",
    "Back View",
    "Inner Side View",
    "Inner Back Side View",
    "Inner Front Side View",
    "Inner Front View",
    "Drive View",
  ];
  const orderedImages = car?.images;
  // .map((viewName) => car?.images?.find((image) => image?.name === viewName))
  // .filter((image) => !!image);
  // const fetchCarModel = async (id) => {
  //   const response = await dispatch(getCarModel(id));
  //   setCarModel(response?.data);
  // };
  const handleImageClick = (index) => {
    setActiveIndex(index);
    setIsModalOpen(true); // Show the modal
  };
  const handleBookCar = (id) => {
    Swal.fire({
      title: `${t("booking_pop_6")}`,
      text: `${t("booking_pop_7")}`,
      icon: "info", // More friendly icon
      showCancelButton: true,
      confirmButtonColor: "#000740", // Custom primary color
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("book_confirm")}`,
      cancelButtonText: `${t("book_cancel")}`,
      background: "#f7f9fc", // Light background
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(bookCar(id));
        console.log(response?.data);

        // if(response?.data)
        // Action to be taken after confirmation
        if (response?.code === 200) {
          console.log(userData);

          const refId = response?.data?.code;
          const email = userData?.email;
          Swal.fire({
            title: `${t("booked")}`,
            html: `<p>${t("booking_pop_1")}<strong>${email}</strong>.</p>
  <p>${t("booking_pop_2")}<strong>${refId}</strong> ${t("booking_pop_3")}</p>
  <p>${t("booking_pop_4")}</p>
  <p><strong>${t("booking_pop_5")}</strong></p>`,
            icon: "success",
            confirmButtonColor: "#000740", // Custom primary color
          });
        }
      }
    });
  };
  const handleMouseEnter = (idx) => {
    setHoveredIdx(idx);
  };
  const setNameCar = () => {
    const {
      steering_type,
      name,
      model,
      engine_size,
      fuel_type,
      transmission,
      ext_color,
      production_year,
    } = car || {};
    const transmissionLabel = car?.transmission === "Automatic" ? "AT" : "ML";
    const carName = `${steering_type ? `(${steering_type})` : ""} ${
      name ? name : ""
    } ${model ? model : ""} ${engine_size ? engine_size : ""} ${
      fuel_type ? fuel_type : ""
    } ${transmissionLabel ? transmissionLabel : ""} ${
      ext_color ? ext_color : ""
    } - ${production_year ? `MY${production_year}` : ""}`;
    return carName.trim();
  };

  const handleMouseLeave = () => {
    setHoveredIdx(null);
  };
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    handleResize();
    fetchCarBySlug(slug);
    if (!isModalOpen) {
      setThumbsSwiper(null); // Reset the thumbs swiper when modal is closed
    }
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // fetchCarModel(id);
    // Generate PDF on component mount

    return () => window.removeEventListener("resize", handleResize);
  }, [isModalOpen, location.search]);

  const getBannerImage = () => {
    const bannerImage = car?.image?.find((item) => item.name === "Banner");
    if (bannerImage) {
      return `${api_url}/${bannerImage?.image?.path}`; // Construct the full path
    } else {
      return bannerPlaceHolder; // Return placeholder image if no banner found
    }
  };

  return (
    <div className={`content-wrapper ${isModalOpen ? "blurred" : ""}`}>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <div className="container">
        <div className="text-center pt-3 pb-3">
          <img
            src={getBannerImage()}
            alt="Logo"
            className="shop-image img-fluid" // Use img-fluid for responsive image
            style={{ maxWidth: "100%" }}
          />

          {/* Ensure the h3 and button are responsive */}
          <div className="d-flex flex-wrap mt-3 justify-content-between align-items-center">
            <h3 className="text-uppercase fw-bold col-12 col-md-10 text-md-start mb-3 mb-md-0">
              {setNameCar()}
            </h3>
            <button
              type="submit"
              className="btn btn-primary btn-lg ps-4 pe-4 font-15 mb-3 col-12 col-md-2"
            >
              {car?.code}
            </button>
          </div>
        </div>

        {/* Images Grid */}
        <div className="row text-center mt-4">
          {orderedImages?.map((image, idx) => (
            <div className="col-12 col-sm-6 col-md-3 mb-3" key={idx}>
              <div className="position-relative">
                <img
                  src={`${api_url}/${image?.image?.path}`}
                  alt={image?.name}
                  className="img-fluid rounded"
                  style={{ objectFit: "cover", width: "100%", height: "auto" }}
                  onClick={() => handleImageClick(idx)}
                />
                <div
                  className="position-absolute"
                  style={{
                    width: "30px",
                    height: "28px",
                    top: "200px",
                    left: "10px",
                    // padding: "5px 11px",
                    gap: "10px",
                    borderRadius: "10px 10px 10px 10px",
                    backgroundColor: "#FFFFFF",
                    // Adding background to make the text readable
                    opacity: "1", // Adjusted opacity to make it visible
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onMouseEnter={() => handleMouseEnter(idx)}
                  onMouseLeave={handleMouseLeave}
                >
                  <i class="bi bi-info-circle font-20 "></i>
                </div>
                {hoveredIdx === idx && (
                  <div
                    className="position-absolute info-tooltip"
                    style={{
                      top: "240px", // Adjust position relative to the info icon
                      left: "10px",
                      backgroundColor: "#000",
                      color: "#fff",
                      padding: "10px",
                      borderRadius: "8px",
                      zIndex: 100,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* Display image name or any other information */}
                    <p className="m-0">{image?.name}</p>
                    {/* <p className="m-0">
                      {image?.description || "No description available"}
                    </p> */}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* PDF and Product List Section */}
        <div className="row mt-5">
          <div className="col-md-5">
            <div className="pdf-section">
              <div
                className="pdf-viewer"
                style={{
                  height: "550px",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  position: "relative",
                }}
              >
                {/* Check if the PDF URL exists */}
                {pdfUrl ? (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer fileUrl={pdfUrl} />
                  </Worker>
                ) : (
                  <p className="text-center pt-5">{t("no_pdf")}</p>
                )}

                {pdfUrl && (
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "#000740",
                      borderRadius: "8px",
                      position: "absolute",
                      bottom: "10px", // Stick to the bottom of the pdf-viewer
                      left: "50%", // Center it horizontally
                      transform: "translateX(-50%)", // Adjust for centering
                      width: "60%", // Adjust the width as needed
                    }}
                    onClick={() => downloadPdf(pdfUrl)}
                  >
                    {t("download_pdf")}
                  </button>
                )}
              </div>

              {/* Optional Download Button */}
              {/* {pdfUrl && (
                <button
                  className="btn btn-primary mt-3 w-100"
                  style={{ backgroundColor: "#4e3DBD", borderRadius: "8px" }}
                  onClick={() => downloadPdf(pdfUrl)}
                >
                  Download PDF
                </button>
              )} */}
            </div>
          </div>

          <div className="col-md-1"></div>

          {/* Product List */}
          <div className="col-md-5 mt-2">
            {/* {[
              {
                image: serviceKitImg,
                name: "Toyota Service Kit",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
              {
                image: maintenanceKitImg,
                name: "Toyota Maintenance Kit",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
              {
                image: accessoriesImg,
                name: "Toyota Accessories",
                short_description: "4.0 D5 PowerPulse Momentum 5dr AW",
                price: "125 AED",
              },
            ].map((item, idx) => (
              <div
                className="card shadow-sm p-3 mb-4 bg-white rounded"
                key={idx}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="img-fluid rounded"
                    style={{ width: "100px", marginRight: "15px" }}
                  />
                  <div>
                    <h6 className="fw-bold">{item?.name}</h6>
                    <span>{item?.short_description}</span>
                    <hr />
                    <div className="d-flex justify-content-between align-items-baseline">
                      <p>{item?.price}</p>
                      <button className="btn btn-primary">
                        {t("add_to_cart")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
        <div className="col-12 d-flex justify-content-center m-3">
          {!car?.is_booked ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleBookCar(car?.id)}
            >
              {t("book")}
            </button>
          ) : (
            <button type="button" className="btn btn-primary">
              {t("booked")}: {car?.booked_code}
            </button>
          )}
        </div>
        <Modal
          show={isModalOpen}
          onHide={() => setIsModalOpen(false)} // Close modal when clicking outside
          size="lg"
          centered
          className="fullscreen-modal"
        >
          <Modal.Body className="modal-blur-content">
            {/* Main Swiper */}
            <Swiper
              navigation
              initialSlide={activeIndex} // Start from the clicked image
              modules={[Navigation, Thumbs]}
              className="mySwiper2 mb-2"
              spaceBetween={10}
              loop={true}
              thumbs={{ swiper: thumbsSwiper }} // Connect to thumbnail Swiper
            >
              {orderedImages?.map((image, idx) => (
                <SwiperSlide key={idx}>
                  <img
                    src={`${api_url}/${image?.image?.path}`}
                    alt={image?.name}
                    style={{ width: "100%", height: "100%" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* Thumbnail Swiper */}
            <Swiper
              onSwiper={setThumbsSwiper} // Set this Swiper as the thumbsSwiper
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[Navigation, Thumbs]}
              className="mySwiper"
              // Add margin for better layout
            >
              {orderedImages?.map((image, idx) => (
                <SwiperSlide key={idx} className="swiper-slider">
                  <img
                    src={`${api_url}/${image?.image?.path}`}
                    alt={image?.name}
                    style={{ width: "100%", height: "auto" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default CarSpecification;
