import axios from "axios";

// const API_URL = 'http://localhost:8000/api/user'; // Replace with your API URL
// const API_URL1 = 'https://api.cruisemotors.co/api/user';
export const API_URL = "https://dev-api.cruisemotors.co/api";
const getAuthToken = () => localStorage.getItem("authToken");

export const getCsrfToken = () => {
  const tokenElement = document.querySelector('meta[name="csrf-token"]');
  return tokenElement ? tokenElement.getAttribute("content") : "";
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
    "X-CSRF-Token": getCsrfToken(), // If using CSRF token
  },
});
 axiosInstance.interceptors.request.use(
   (config) => {
    const token = getAuthToken();
     if (token) {
       config.headers.Authorization = `Bearer ${token}`;
     }
     return config;
   },
   (error) => {
     return Promise.reject(error);
   }
 );

export const signIn = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/login`, credentials);
    return response.data;
  } catch (error) {
    throw error?.response?.data || "Sign In Failed";
  }
};

export const signUp = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/register`, credentials);
    return response.data;
  } catch (error) {
    throw error.response.data || "Sign Up Failed Somthing Wrong!";
  }
};

export const companySignUp = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/register`, credentials);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Company Sign Up Failed Somthing Wrong!";
  }
};

export const signOut = async () => {
  try {
    await axios.post(`${API_URL}/signout`);
  } catch (error) {
    throw "Sign Out Failed";
  }
};

export const tendorSubmit = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/tender/store`,
      credentials
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || "Submission failed";
  }
};

export const requestQuotationSubmit = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/quotation/store`,
      credentials
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || "Submission failed";
  }
};

export const makeaDealSubmit = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/deal/store`,
      credentials
    );
    return response.data;
  } catch (error) {
    throw error?.response?.data || "Submission failed";
  }
};

export const supplyContractSubmit = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/supply-contract/store`,
      credentials
    );
    return response.data;
  } catch (error) {
    throw "Submission failed";
  }
};

export const requestOtp = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/send-code`, credentials);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Sign In Failed";
  }
};

export const verifyOtp = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/verify-code`, credentials);
    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const resetPassword = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/reset-password`, credentials);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Sign In Failed";
  }
};

export const offerVehicle = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/offer-vehicle/store`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Sign In Failed";
  }
};

export const orderVehicle = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/order-vehicle/store`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Sign In Failed";
  }
};

export const getCategoriesApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product-category`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getAccessoriesApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/by-category/accessories`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getSparePartsApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/by-category/spare-parts`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getServiceKitsApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/by-category/service-kit`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getBrandListAPi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car-make`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getFeatureBrandListAPi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car-make/featured/${credentials}`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getWishListAPi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/wishlist`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const removeWishListApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/update-wishlist/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getProductDetailsApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/show/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCarMakeApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/car-make`);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
// export const getCarModelApi = async (credentials) => {
//   try {
//     const response = await axiosInstance.get(
//       `${API_URL}/car-model/by-car-make-slug/${credentials}`
//     );

//     return response.data;
//   } catch (error) {
//     throw error?.response?.data || "Request Failed";
//   }
// };
export const getCarModelApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car-model/by-car-make-slug/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getCountryApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/location/countries`);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getStateApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/location/states/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getCityApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/location/cities/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getPortApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car-model/by-car-make/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCartApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/cart/get-items-by-user/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const cartUpdateApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/cart/update-item`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const contactUsApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/inquiry/store`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getPaymentMethodApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/payment-method/get-payment-methods`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getNewArrivalsApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/product/by-category/accessories`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCarsByBrandApi = async (credentials) => {
  try {
    console.log("crddd:", credentials);

    const response = await axiosInstance.get(`${API_URL}/car/listing`, {
      params: credentials,
    });

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCarBySlugApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car/detail/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCertificatesApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/documentation/service/active-services`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const documentationFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/documentation/store`,
      credentials
    );
    return response.data;
  } catch (error) {
    throw "Submission failed";
  }
};

export const bookCarApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/car/book/${credentials}`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getInquiriesApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(`${API_URL}/user-panel/inquiries`);

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getDealFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getOrderVehicleFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getSupplyContractFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getTenderFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getQuotationFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getDocumentationFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
export const getOfferVehicleFormApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/get-forms`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const getCarBookingsApi = async (credentials) => {
  try {
    const response = await axiosInstance.get(
      `${API_URL}/user-panel/car-bookings`
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const updateProfileApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/update/profile`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};

export const updatePasswordApi = async (credentials) => {
  try {
    const response = await axiosInstance.post(
      `${API_URL}/user-panel/update/password`,
      credentials
    );

    return response.data;
  } catch (error) {
    throw error?.response?.data || "Request Failed";
  }
};
