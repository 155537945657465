import "./ServicePage.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/make-a-deal.png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import { Navigate } from "react-router-dom";
import { useHistory } from "react-router-dom";
import documentlogo from "../assets/images/gallerylogo.png";
import { useNavigate } from "react-router-dom";
const ReviewPage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const { formData } = location.state || {}; // Extract formData passed from ServicePage
  console.log("formData", formData);

  // Inside your ReviewPage component
  //   const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();

  const handlePayment = () => {
    navigate("/documentspayments", {
      state: {
        subtotal: formData.subtotal,
        grandTotal: formData.grandTotal,
      },
    });
  };
  //   const handlePayNow = () => {
  //     history.push("/payment", {
  //       subtotal: formData.subtotal,
  //       grandTotal: formData.grandTotal,
  //     });
  //   };
  const CertificationSection = ({ certificates }) => {
    return (
      <div style={{ marginRight: "5px", float: "right" }}>
        {certificates
          .filter((certificate) => certificate.selected) // Only show selected certificates
          .map((certificate) => (
            <div
              className="mb-3"
              key={certificate.id}
              style={{ width: "250px", height: "39px" }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "16px" }}>{certificate.name}</label>
                <i
                  className="bi bi-check-circle certificate-icon"
                  style={{
                    height: "16px",
                    width: "16px",
                    backgroundColor: "#00B80F",
                    color: "white",
                    borderRadius: "50%",
                    padding: "5px",
                    border: "2px solid #ced4da",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></i>
              </div>
              <hr className="line" style={{ marginTop: "5px" }} />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5 table-responsive">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7" style={{ width: "1038px" }}>
              <img
                src={banner}
                alt="Banner"
                className="img-fluid mb-5"
                style={{ height: "494px", width: "1308px" }}
              />
              <h2
                className="text-center fw-bold"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                Documentation & Certification
              </h2>
              <div
                className="container table-responsive border align-content-center custom-border p-3"
                style={{
                  height: "464px",
                  width: "743px",
                  borderRadius: "20px",
                }}
              >
                <div className="row table-responsive ">
                  {/* Vertical Line */}
                  {/* <hr style={{rotate:}}></hr> */}
                  <div
                    className="col-md-7 order-right-only"
                    style={{
                      borderRight:
                        "2px solid #ced4da" /* Adjust color and thickness as needed */,
                      borderTop: "none",
                      borderBottom: "none",
                      borderLeft: "none",
                    }}
                  >
                    {/* Vehicle Details Section */}
                    <div className="row mb-3">
                      <div className="col-md-5 text-muted">Make</div>
                      <div className="col-md-5 text-muted">Model</div>
                      <div
                        className="col-md-2 text-muted"
                        style={{
                          marginRight: 0,
                          float: "right",
                          display: "flex",
                        }}
                      >
                        Year
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-5">
                        {formData?.selectedCarMake}
                      </div>
                      <div className="col-md-5">
                        {formData.selectedCarModel}
                      </div>
                      <div className="col-md-2">{formData.year}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-muted">VIN Number</div>
                      <div className="col-md-12">{formData.vin}</div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-12 text-muted">Comments</div>
                      <div className="col-md-12">{formData.comments}</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-5 text-muted mb-3">
                        INVOICES
                        <div
                          className="border d-flex justify-content-center align-items-center"
                          style={{
                            height: "128px",
                            width: "128px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            style={{ width: "53px", height: "53px" }}
                            src={documentlogo}
                          ></img>
                        </div>
                      </div>
                      <div className="col-md-5 ">
                        <label className="text-muted">EXPORT_DOCUMENTS</label>
                        <div
                          className="border d-flex justify-content-center align-items-center"
                          style={{
                            height: "128px",
                            width: "128px",
                            borderRadius: "10px",
                          }}
                        >
                          <img
                            style={{ width: "53px", height: "53px" }}
                            src={documentlogo}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col table-responsive">
                    {/* Certification Section */}
                    <CertificationSection
                      certificates={formData.certificates}
                    />
                  </div>
                  <div></div>
                </div>
              </div>
              <div>
                <div
                  className="totals mt-4"
                  style={{
                    width: "743px",
                    margin: "0 auto",
                    marginBottom: "8px",
                  }}
                >
                  <div className="d-flex flex-column align-items-end">
                    <div
                      className="text-end"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <label style={{ marginBottom: "8px" }}>
                        <span style={{ marginRight: "100px" }}>Sub Total:</span>
                        <span className="ms-2">{formData.subtotal} AED</span>
                      </label>
                      <hr
                        className="line"
                        style={{ width: "100%", margin: "0" }}
                      />
                    </div>
                    <div
                      className="text-end"
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        width: "100%",
                      }}
                    >
                      <label style={{ marginBottom: "8px" }}>
                        <span style={{ marginRight: "100px" }}>
                          <strong>Grand Total:</strong>
                        </span>
                        <span className="ms-2">{formData.grandTotal} AED</span>
                      </label>
                      <hr
                        className="line"
                        style={{ width: "100%", margin: "0" }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <button
                    type="submit"
                    className="btn btn-primary w-267 h-52"
                    onClick={handlePayment}
                  >
                    Continue to Payment
                  </button>
                  <p style={{ marginTop: "10px", color: "gray" }}>
                    We usually respond between 30-50 minutes ...
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    padding: "15px",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "400",
                      lineHeight: "40px",
                      fontSize: "20px",
                    }}
                  >
                    Documentation and certificates are integral components in
                    the automotive industry, ensuring the safety, reliability,
                    and legal compliance of vehicles. Documentation for cars
                    includes a range of materials such as user manuals,
                    maintenance records, service logs, and technical guides.
                    These documents provide essential information for car
                    owners, mechanics, and technicians, covering everything from
                    operating instructions to detailed maintenance schedules and
                    repair procedures. Proper documentation helps in keeping
                    track of a vehicle’s history, ensuring that it is maintained
                    according to manufacturer recommendations and preserving its
                    resale value.
                  </p>
                </div>

                {/* Other content */}
              </div>
            </div>
          </div>
        </div>
      </div>
       <Footer />
    </div>
  );
};

export default ReviewPage;
