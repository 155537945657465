const getMainMenu = (t) => {
  return [
    // {
    //   key: 1,
    //   icon: "bi bi-truck", // Bootstrap Icon class for a truck
    //   label: "Brands",
    //   path: "/brands",
    //   children: [
    //     { key: 11, icon: "bi bi-badge-ad", label: "Acura", path: "/" },
    //     { key: 12, icon: "bi bi-badge-ad", label: "Audi", path: "/" },
    //     { key: 13, icon: "bi bi-badge-ad", label: "Bentley", path: "/" },
    //   ],
    // },
    {
      key: 2,
      label: t("menu.services"),
      path: "/services",
      children: [
        {
          key: 21,
          label: t("menu.tender"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/tender",
        },
        {
          key: 22,
          label: t("menu.supply_contract"),
          icon: <i className="bi bi-box"></i>,
          path: "/supply-contract",
        },
        {
          key: 23,
          label: t("menu.spare_parts"),
          icon: <i className="bi bi-tools"></i>,
          path: "/spare-parts",
        },
        {
          key: 24,
          label: t("menu.service_kits"),
          icon: <i className="bi bi-gear"></i>,
          path: "/service-kits",
        },
        {
          key: 25,
          label: t("menu.warranty"),
          icon: <i className="bi bi-shield-check"></i>,
          path: "/warranty-information",
        },
        {
          key: 26,
          label: t("menu.cross_trade"),
          icon: <i className="bi bi-arrow-left-right"></i>,
          path: "/cross-trade",
        },
      ],
    },
    {
      key: 3,
      label: t("menu.logistics"),
      path: "/logistics",
      children: [
        {
          key: 31,
          label: t("menu.request_for_quotation"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/request-for-quotation",
        },
        {
          key: 32,
          label: t("menu.documentation_certificates"),
          icon: <i className="bi bi-file-earmark-medical"></i>,
          path: "/documents",
        },
      ],
    },
    {
      key: 4,
      label: t("menu.modifications"),
      path: "/modifications",
    },
    {
      key: 5,
      label: t("menu.payment_method"),
      path: "/payment-method",
      children: [
        {
          key: 41,
          label: t("menu.bank_account_details"),
          icon: <i className="bi bi-bank"></i>,
          path: "/bank-detail",
        },
        {
          key: 42,
          label: t("menu.letter_of_credit"),
          icon: <i className="bi bi-file-earmark-text"></i>,
          path: "/letter-of-credit",
        },
        {
          key: 43,
          label: t("menu.cash"),
          icon: <i className="bi bi-cash-stack"></i>,
          path: "/cash",
        },
        {
          key: 44,
          label: t("menu.rules_60_40"),
          icon: <i className="bi bi-percent"></i>,
          path: "/rules",
        },
        {
          key: 45,
          label: t("menu.usdt_crypto"),
          icon: <i className="bi bi-coin"></i>,
          path: "/crypto-currency",
        },
        {
          key: 46,
          label: t("menu.terms_conditions"),
          icon: <i className="bi bi-file-earmark-lock"></i>,
          path: "/terms-and-condition",
        },
      ],
    },
    {
      key: 6,
      label: t("menu.make_a_deal"),
      path: "/make-a-deal",
    },
  ];
};

const getFooterMenu = (t) => {
  return [
    { name: t("menu.footer.home"), path: "/" },
    { name: t("menu.footer.about"), path: "/about" },
    { name: t("menu.footer.contact"), path: "/contact" },
  ];
};

export default { getMainMenu, getFooterMenu };
