import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
  getDealForm,
  getOrderVehicleForm,
  getSupplyContractForm,
  getTenderForm,
  getQuotationForm,
  getDocumentationForm,
  getOfferVehicleForm,
} from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload, Table, Tag, Collapse, Spin } from "antd";
import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";
import TrackingModal from "../components/TrackingModal";

const { Panel } = Collapse;

const FormTracking = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const [dealData, setDealdata] = useState([]);
  const [offerVehicleData, setOfferVehicleData] = useState([]);
  const [orderVehicleData, setOrderVehicleData] = useState([]);
  const [supplyContractData, setSupplyContractData] = useState([]);
  const [tenderData, setTenderData] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const [documentationData, setDocumentationData] = useState([]);

  const [loadingDeal, setLoadingDeal] = useState(false);
  const [loadingOfferVehicle, setLoadingOfferVehicle] = useState(false);
  const [loadingOrderVehicle, setLoadingOrderVehicle] = useState(false);
  const [loadingSupplyContract, setLoadingSupplyContract] = useState(false);
  const [loadingTender, setLoadingTender] = useState(false);
  const [loadingQuotation, setLoadingQuotation] = useState(false);
  const [loadingDocumentation, setLoadingDocumentation] = useState(false);
  const [trackingModalVisible, setTrackingModalVisible] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [formData, setFormData] = useState([]);
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const fetchDealForm = async () => {
    setLoadingDeal(true);
    const response = await dispatch(getDealForm({ type: "deal" }));
    console.log("deal:", response?.data);
    setDealdata(response?.data);
    setLoadingDeal(false);
  };

  const fetchOrderVehicleForm = async () => {
    setLoadingOrderVehicle(true);
    const response = await dispatch(
      getOrderVehicleForm({ type: "order_vehicle" })
    );
    console.log("deal:", response?.data);
    setOrderVehicleData(response?.data);
    setLoadingOrderVehicle(false);
  };

  const fetchSupplyContractForm = async () => {
    setLoadingSupplyContract(true);
    const response = await dispatch(getDealForm({ type: "supply_contract" }));
    console.log("deal:", response?.data);
    setSupplyContractData(response?.data);
    setLoadingSupplyContract(false);
  };

  const fetchTenderForm = async () => {
    setLoadingTender(true);
    const response = await dispatch(getTenderForm({ type: "tender" }));
    console.log("deal:", response?.data);
    setTenderData(response?.data);
    setLoadingTender(false);
  };

  const fetchQuotationForm = async () => {
    setLoadingQuotation(true);
    const response = await dispatch(getQuotationForm({ type: "quotation" }));
    console.log("deal:", response?.data);
    setQuotationData(response?.data);
    setLoadingQuotation(false);
  };

  const fetchDocumentationForm = async () => {
    setLoadingDocumentation(true);
    const response = await dispatch(getTenderForm({ type: "documentation" }));
    console.log("deal:", response?.data);
    setDocumentationData(response?.data);
    setLoadingDocumentation(false);
  };

  const fetchOfferVehicleForm = async () => {
    setLoadingOfferVehicle(true);
    const response = await dispatch(getTenderForm({ type: "offer_vehicle" }));
    console.log("deal:", response?.data);
    setOfferVehicleData(response?.data);
    setLoadingOfferVehicle(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    fetchDealForm();
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        return new Date(created_at).toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Status",
      dataIndex: "trackable", // trackable contains the statuses
      key: "status",
      render: (trackable) => {
        // Access the latest status from the trackable array
        const latestTrackable = trackable?.[0]?.status || "Unknown";
        console.log(latestTrackable);

        let color = "";

        // Map statuses to colors
        switch (latestTrackable) {
          case "CREATED":
            color = "orange";
            break;
          case "RECEIVED":
            color = "blue";
            break;
          case "REVIEWED":
            color = "yellow";
            break;
          case "REPLIED":
            color = "cyan";
            break;
          case "CONTACTED":
            color = "purple";
            break;
          case "RESOLVED":
            color = "green";
            break;
          case "CLOSED":
            color = "red";
            break;
          case "CANCELLED":
            color = "gray";
            break;
          case "VERIFIED":
            color = "lime";
            break;
          case "PENDING":
            color = "gold";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "REJECTED":
            color = "red";
            break;
          default:
            color = "default";
            break;
        }

        return <Tag color={color}>{latestTrackable}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleRead(record)}>
          Track
        </Button>
      ),
    },
  ];

  // Handle the Read button click
  const handleRead = (record) => {
    console.log(`Marking as read: ${record.message}`);
    setTrackingData(record?.trackable);
    setFormData(record);
    setTrackingModalVisible(true);
    // You can add your "read" logic here (e.g., updating the status or redirecting)
  };
  const handleCloseModal = () => setTrackingModalVisible(false);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        {" "}
        {/* Adjust container to fluid for responsiveness */}
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="mb-4 text-center text-md-start">My Dashboard</h3>{" "}
              {/* Center text on small screens */}
              <div className="row">
                <div className="col-12 col-md-3 mb-4">
                  {" "}
                  {/* Adjust column sizes for mobile */}
                  <UserDashboardSideMenu />
                </div>
                <div className="col-12 col-md-9">
                  <div className="card bg-white border-0 rounded-10">
                    <div className="card-body">
                      <form>
                        <div className="card rounded-10 bg-white border-0 mb-5">
                          <div className="card-body p-3 p-md-5">
                            {" "}
                            {/* Adjust padding for mobile */}
                            <h4 className="mb-4">Form Tracking</h4>
                            <Collapse defaultActiveKey={["1"]}>
                              {/* Collapse Panel 1 */}
                              <Panel
                                header="Make a Deal"
                                key="1"
                                extra={loadingDeal && <Spin />}
                              >
                                <div className="table-responsive">
                                  {" "}
                                  {/* Make table scrollable on mobile */}
                                  <Table
                                    dataSource={dealData}
                                    columns={columns}
                                    loading={loadingDeal}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 2 */}
                              <Panel
                                header="Offer Us A vehicle"
                                key="2"
                                extra={loadingOfferVehicle && <Spin />}
                                onClick={fetchOfferVehicleForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={offerVehicleData}
                                    columns={columns}
                                    loading={loadingOfferVehicle}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 3 */}
                              <Panel
                                header="Order A Vehicle"
                                key="3"
                                extra={loadingOrderVehicle && <Spin />}
                                onClick={fetchOrderVehicleForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={orderVehicleData}
                                    columns={columns}
                                    loading={loadingOrderVehicle}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 4 */}
                              <Panel
                                header="Quotation"
                                key="4"
                                extra={loadingQuotation && <Spin />}
                                onClick={fetchQuotationForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={quotationData}
                                    columns={columns}
                                    loading={loadingQuotation}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 5 */}
                              <Panel
                                header="Tenders"
                                key="5"
                                extra={loadingTender && <Spin />}
                                onClick={fetchTenderForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={tenderData}
                                    columns={columns}
                                    loading={loadingTender}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 6 */}
                              <Panel
                                header="Supply Contract"
                                key="6"
                                extra={loadingSupplyContract && <Spin />}
                                onClick={fetchSupplyContractForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={supplyContractData}
                                    columns={columns}
                                    loading={loadingSupplyContract}
                                  />
                                </div>
                              </Panel>

                              {/* Collapse Panel 7 */}
                              <Panel
                                header="Certification and Documentations"
                                key="7"
                                extra={loadingDocumentation && <Spin />}
                                onClick={fetchDocumentationForm}
                              >
                                <div className="table-responsive">
                                  <Table
                                    dataSource={documentationData}
                                    columns={columns}
                                    loading={loadingDocumentation}
                                  />
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <TrackingModal
        visible={trackingModalVisible}
        onClose={handleCloseModal}
        trackingData={trackingData}
        details={formData}
      />
    </div>
  );
};

export default FormTracking;
