import React from "react";
import imgPlaceholder from "../assets/images/img-placeholder.png";
import { Badge, Card } from "antd";
import { useNavigate } from "react-router-dom";
import "./ItemCard.css"; // Create this CSS file for additional custom styling
import { API_URL } from "../api/authApi";
const { Meta } = Card;

const ItemCard = ({
  productData,
  btnText,
  fromPage,
  FromWishlist,
  updateCart,
}) => {
  const navigate = useNavigate();
  const api_url = API_URL.replace("/api", "");
  const cartUpdate = (id, method) => {
    updateCart(id, method);
  };
  const addToWishlist = (id) => {};

  const goToProductDetails = () => {
    navigate(`/product-details/${productData.id}`, {
      state: { fromPage, id: productData.id }, // Pass the fromPage as state
    });
  };

  return (
    <div className="product-card" style={{ position: "relative" }}>
      <Card
        hoverable
        onClick={goToProductDetails}
        cover={
          <img
            className="product-image"
            alt={productData.name}
            src={
              productData?.images && productData?.images?.length > 0
                ? `${api_url}/${productData?.images?.[0]?.image?.path}` // Use the first image's path with the base URL
                : imgPlaceholder // Fallback to the placeholder if no images are present
            }
          />
        }
      >
        <Meta
          title={
            <>
              <div className="product-title">{productData.name}</div>
              <div className="product-description">
                {productData.short_description}
              </div>
            </>
          }
        />
{/* This condition is added here bcs the same component is using in newArrivals */}
        {fromPage !== "Home" && (
          <>
            <hr />
            <div className="item-card-footer">
              <div className="product-price">{productData?.price} AED</div>
              <div className="add-to-cart">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (productData?.is_in_cart) {
                      cartUpdate(productData?.id, "remove"); // Call the remove from cart function
                    } else {
                      cartUpdate(productData?.id, "add"); // Call the add to cart function
                    }
                  }}
                >
                  {productData?.is_in_cart ? "Remove From Cart" : "Add To Cart"}
                </button>
              </div>
            </div>
          </>
        )}
      </Card>
{/* This condition is added here bcs the same component is using in newArrivals */}
      {/* Wishlist button */}
      {fromPage !== "Home" && (
        <button
          type="button"
          className="wishlist-btn"
          onClick={() => FromWishlist(productData?.id)} // Handle wishlist action
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          {productData?.is_in_wishlist ? (
            <i
              className="bi bi-heart-fill"
              style={{ fontSize: "1.5rem", color: "red" }}
            ></i> // Filled heart if in wishlist
          ) : (
            <i
              className="bi bi-heart"
              style={{ fontSize: "1.5rem", color: "#282c34" }}
            ></i> // Outline heart if not in wishlist
          )}
        </button>
      )}
    </div>
  );
};

export default ItemCard;
