import {
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SIGN_UP_SUCCESS,
  ADVANCE_SIGN_UP_SUCCESS,
  COMPANY_SIGN_UP_SUCCESS,
  COMPANY_ADVANCE_SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SET_LANGUAGE,
  TENDOR_SUBMIT,
  SUPPLY_CONTRACT_SUBMIT,
  REQUEST_QUOTATION_SUBMIT,
  MAKE_A_DEAL_SUBMIT,
  GET_CAR_MAKE,
  GET_CAR_MODEL,
  GET_FEATURED_BRAND_LIST,
  GET_ALL_BRAND_LIST,
  GET_ALL_COUNTRY,
  GET_FEATURED_BRAND_LIST_WITH_CARS,
  SET_USERDATA,
} from "./authTypes";

const initialState = {
  isAuthenticated: false,
  user: {},
  error: null,
};

export const authReducer = (state = initialState, action) => {
  console.log("authReducer:", action);

  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
      };
    case COMPANY_SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SIGN_OUT:
      return initialState;
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        error: null,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
        error: null,
      };
    case TENDOR_SUBMIT:
      return {
        ...state,
        tendor: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case SUPPLY_CONTRACT_SUBMIT:
      return {
        ...state,
        supplyContract: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case REQUEST_QUOTATION_SUBMIT:
      return {
        ...state,
        requestQuotation: action.payload,
        error: null,
      };
    case MAKE_A_DEAL_SUBMIT:
      return {
        ...state,
        makeaDeal: action.payload,
        error: null,
      };
    case GET_CAR_MAKE:
      return {
        ...state,
        carMake: action?.payload,
        isAuthenticated: true,
        error: null,
      };
    case GET_CAR_MODEL:
      return {
        ...state,
        carModel: action?.payload,
        isAuthenticated: true,
        error: null,
      };
    case GET_FEATURED_BRAND_LIST:
      return {
        ...state,
        featuredBrands: action?.payload,
        error: null,
      };
    case GET_ALL_BRAND_LIST:
      return {
        ...state,
        allBrands: action?.payload,
        error: null,
      };
    case GET_ALL_COUNTRY:
      return {
        ...state,
        countries: action?.payload,
        error: null,
      };
    case GET_FEATURED_BRAND_LIST_WITH_CARS:
      return {
        ...state,
        featuredBrandsWithCars: action?.payload,
        error: null,
      };
    case SET_USERDATA:
      return {
        ...state,
        user: {
          ...state.user, // Spread the existing user object
          data: {
            ...state.user.data, // Spread the existing data object
            user: action.payload, // Update the user inside data
          },
        },
        error: null,
      };
    case "CLEAR_AUTH_ERROR":
      return {
        ...state,
        error: [], // Reset the error
      };
    default:
      return state;
  }
};
