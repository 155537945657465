import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const WarrantyInformation = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2 className="mb-4  text-center">{t("warranty_info")}</h2>

              <p className="text-muted">
                At Cruise Motors, we stand by the quality and reliability of our
                products/services. That's why we offer a robust warranty program
                designed to protect your investment and ensure your complete
                satisfaction. Coverage Duration: Our warranty covers all
                eligible products/services for a period of [X years/months] from
                the date of purchase. Extended warranty options are available
                for added protection.
              </p>

              <p className="text-muted">
                Defective Materials: If any material used in the product is
                found to be defective within the warranty period, we will repair
                or replace the defective parts at no cost to you. Manufacturing
                Defects: We will cover any defects arising from errors in
                manufacturing that affect the normal operation of the
                product/service. While our warranty covers most aspects of your
                purchase, it does not extend to normal wear and tear from
                regular use. Damage resulting from improper use, accidents, or
                unauthorized modifications. Products/services that have been
                subjected to negligence, abuse, or misuse. Making a warranty
                claim is simple and hassle-free. If you encounter an issue
                covered by the warranty, Contact Us: Reach out to our customer
                support team with your purchase information and details about
                the issue. Contact Us: Reach out to our customer support team
                with your purchase information and details about the issue. For
                those who want extra peace of mind, we offer extended warranty
                plans that provide additional years of coverage beyond the
                standard warranty period. Talk to our team to learn more about
                extending your protection.
              </p>

              <p className="text-muted">
                No Hidden Fees: Our warranty covers eligible repairs and
                replacements without any surprise costs. Fast & Friendly
                Support: We prioritize customer satisfaction and aim to resolve
                warranty claims quickly and efficiently. Reliable Service: We
                are committed to ensuring that your experience with our
                products/services remains worry-free long after the purchase.
              </p>

              <p className="text-muted">
                At Cruise Motors, we stand by the quality and reliability of our
                products/services. That's why we offer a robust warranty program
                designed to protect your investment and ensure your complete
                satisfaction. Coverage Duration: Our warranty covers all
                eligible products/services for a period of [X years/months] from
                the date of purchase. Extended warranty options are available
                for added protection.
              </p>

              <p className="text-muted">
                At Cruise Motors, we stand by the quality and reliability of our
                products/services. That's why we offer a robust warranty program
                designed to protect your investment and ensure your complete
                satisfaction. Coverage Duration: Our warranty covers all
                eligible products/services for a period of [X years/months] from
                the date of purchase. Extended warranty options are available
                for added protection.
              </p>

              <p className="text-muted">
                No Hidden Fees: Our warranty covers eligible repairs and
                replacements without any surprise costs. Fast & Friendly
                Support: We prioritize customer satisfaction and aim to resolve
                warranty claims quickly and efficiently.
              </p>

              <div className="row g-3 mb-4 text-center">
                <div className="col-12 col-sm-12">
                  <Link
                    to="/contact-us"
                    className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                  >
                    {t("contact_us_for")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WarrantyInformation;
