import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
  getCarBookings,
} from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload, Table, Tag } from "antd";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";
import TrackingModal from "../components/TrackingModal";
const CarBookings = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingDeal, setLoadingDeal] = useState(false);
  const [trackingModalVisible, setTrackingModalVisible] = useState(false);
  const [trackingData, setTrackingData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [carBookings, setCarBookings] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: null,
    vehicleSpecs: null,
    purchaseOrder: null,
    letterOfIntent: null,
  });

  const [fileList, setFileList] = useState({
    tradeLicense: [],
    purchaseOrder: [],
  });

  const fetchCarBookings = async () => {
    setLoadingDeal(true);
    const response = await dispatch(getCarBookings({ type: "deal" }));
    console.log("deal:", response?.data);
    setCarBookings(response?.data);
    setLoadingDeal(false);
  };
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    fetchCarBookings();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Sample data for the table
  const dataSource = [
    {
      key: "1",
      productCategory: "Electronics",
      subCategory: "Mobile Phones",
      quantity: 10,
      price: 299,
      total: 2990,
      status: "Delivered",
    },
    {
      key: "2",
      productCategory: "Clothing",
      subCategory: "T-Shirts",
      quantity: 20,
      price: 15,
      total: 300,
      status: "Delivered",
    },
    {
      key: "3",
      productCategory: "Groceries",
      subCategory: "Fruits",
      quantity: 30,
      price: 3,
      total: 90,
      status: "Pending",
    },
  ];

  // Column definition for the table
  const columns = [
    {
      title: "No",
      dataIndex: "id", // 'id' used from your inquiries array
      key: "id",
      render: (text, record, index) => index + 1, // to render serial numbers starting from 1
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Status",
      dataIndex: "trackable", // trackable contains the statuses
      key: "status",
      render: (trackable) => {
        // Access the latest status from the trackable array
        const latestTrackable = trackable?.[0]?.status || "Unknown";
        console.log(latestTrackable);

        let color = "";

        // Map statuses to colors
        switch (latestTrackable) {
          case "CREATED":
            color = "orange";
            break;
          case "RECEIVED":
            color = "blue";
            break;
          case "REVIEWED":
            color = "yellow";
            break;
          case "REPLIED":
            color = "cyan";
            break;
          case "CONTACTED":
            color = "purple";
            break;
          case "RESOLVED":
            color = "green";
            break;
          case "CLOSED":
            color = "red";
            break;
          case "CANCELLED":
            color = "gray";
            break;
          case "VERIFIED":
            color = "lime";
            break;
          case "PENDING":
            color = "gold";
            break;
          case "APPROVED":
            color = "green";
            break;
          case "REJECTED":
            color = "red";
            break;
          default:
            color = "default";
            break;
        }

        return <Tag color={color}>{latestTrackable}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleRead(record)}>
          Track
        </Button>
      ),
    },
  ];

  // Handle the Read button click
  const handleRead = (record) => {
    console.log(`Marking as read: ${record.message}`);
    setTrackingData(record?.trackable);
    setFormData(record);
    setTrackingModalVisible(true);
    // You can add your "read" logic here (e.g., updating the status or redirecting)
  };

  const handleCloseModal = () => setTrackingModalVisible(false);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <h3 className="mb-4 text-center text-md-start">My Dashboard</h3>
            </div>
            <div className="col-12 col-md-3 mb-4">
              <UserDashboardSideMenu />
            </div>
            <div className="col-12 col-md-9">
              <div className="card bg-white border-0 rounded-10">
                <div className="card-body">
                  <form>
                    <div className="card rounded-10 bg-white border-0 mb-5">
                      <div className="card-body p-3 p-md-5">
                        <h4 className="mb-4 text-center text-md-start">
                          Car Bookings
                        </h4>
                        <div className="table-responsive">
                          <Table
                            dataSource={carBookings}
                            columns={columns}
                            pagination={{ pageSize: 5 }} // Adjust pagination for smaller screens
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <TrackingModal
        visible={trackingModalVisible}
        onClose={handleCloseModal}
        trackingData={trackingData}
        details={formData}
      />
    </div>
  );
};

export default CarBookings;
