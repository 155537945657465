import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitRequestQuotationForm,
  offerVehicleForm,
  getCarMake,
  getCarModel,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/logistics.png";
import log1 from "../assets/images/log1.png";
import log2 from "../assets/images/log2.png";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { message, Upload } from "antd";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const OfferVehicle = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const [rows, setRows] = useState([
    { quantity: 1, car_make_id: "", car_model_id: "", year: "" },
  ]);
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    location: "",
    client_name: "",
    client_phone: "",
    company_name: "",
    designation: "",
    interior_color: "",
    exterior_color: "",
    production_date: "",
    engine_size: "",
    fuel_type: "",
    steering: "",
    asking_price: "",
    sale_method: "",
    comment: "",
  });
  const initialFormData = {
    location: "",
    client_name: "",
    client_phone: "",
    company_name: "",
    designation: "",
    interior_color: "",
    exterior_color: "",
    production_date: "",
    engine_size: "",
    fuel_type: "",
    steering: "",
    asking_price: "",
    sale_method: "",
    comment: "",
  };
  const [errors, setErrors] = useState({});
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const validate = () => {
    let formErrors = {};
    let rowErrors = [];
    if (!formData.client_name) formErrors.client_name = t("errors.client_name");
    if (!formData.client_phone)
      formErrors.client_phone = t("errors.client_phone");
    if (!formData.location) formErrors.location = t("errors.client_address");
    if (!formData.company_name)
      formErrors.company_name = t("errors.company_name");

    // Validate rows
    rows.forEach((row, index) => {
      let rowError = {};
      if (!row.car_make_id) rowError.car_make_id = t("errors.make");
      if (!row.car_model_id) rowError.car_model_id = t("errors.model");
      if (!row.year) rowError.year = t("errors.year");
      if (Object.keys(rowError).length > 0) {
        rowErrors[index] = rowError;
      }
    });

    return { ...formErrors, rowErrors };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRowChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        const brandSlug = selectedBrand.slug;
        console.log("Brand Slug:", brandSlug); // You can remove this line after testing
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }
    const updatedRows = [...rows];
    updatedRows[index] = { ...updatedRows[index], [name]: value };
    setRows(updatedRows);
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const addRow = () => {
    setRows([...rows, { quantity: 1, make: "", model: "", year: "" }]);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const increment = (index) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity += 1;
    setRows(updatedRows);
  };

  const decrement = (index) => {
    const updatedRows = [...rows];
    if (updatedRows[index].quantity > 1) {
      updatedRows[index].quantity -= 1;
    }
    setRows(updatedRows);
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      client_phone: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      delivery_date: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();

    // if (Object.keys(formErrors).length > 0) {
    //     setErrors(formErrors);
    //     return;
    // }
    if (Object.keys(formErrors).length > 1) {
      setErrors(formErrors);
      return;
    }
    if (formErrors?.rowErrors?.length > 0) {
      setErrors(formErrors);
      return;
    }

    // Combine form data with row data
    const combinedData = {
      ...formData,
      offer_vehicle_items: rows,
    };

    const response = await dispatch(offerVehicleForm(combinedData));
    if (response?.code == 200) {
      message.success("Submitted successfully!");
      setRefId(response?.data?.code);
      setFormSubmitted(true);
      setFormData(initialFormData);
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };
  const handleProductionDateChange = (date) => {
    setFormData({
      ...formData,
      product_date_time: date,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    // reduxState?.countries ? setCountry(reduxState?.countries) : fetchCountry();
    if (userData) {
      // If user is a company, fill both user and company details
      if (userData.company) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: userData.company.name || "", // Fill company name
          company_address: userData.company.address || "", // Fill company address
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      } else {
        // If user is not a company, fill only personal details
        setFormData((prevData) => ({
          ...prevData,
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      }
    }

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="servicesPageList mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <div className="card rounded-10 bg-white border-0 mb-5">
                <div className="card-body p-5">
                  <h2 className="mb-4 text-center">Offer Us Vehicle</h2>
                  {formSubmitted ? (
                    <SuccessForm
                      refId={refId}
                      resetForm={() => {
                        setFormSubmitted(false);
                      }}
                    />
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-person me-2"></i>{" "}
                        <span>{t("client_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("client_name")}*`}
                            type="text"
                            name="client_name"
                            value={formData.client_name}
                            onChange={handleChange}
                            customClass={errors.client_name ? "is-invalid" : ""}
                          />
                          {errors.client_name && (
                            <div className="text-danger font-13">
                              {errors.client_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="text-start">
                            <PhoneInput
                              country={"ae"}
                              excludeCountries={["il"]}
                              name="client_phone"
                              value={formData.client_phone}
                              className="w-100"
                              onChange={handlePhoneChange}
                              inputClass={`w-100 ${
                                errors.client_phone ? "is-invalid" : ""
                              }`}
                            />
                            {errors.client_phone && (
                              <div className="text-danger font-13">
                                {errors.client_phone}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("location")}*`}
                            type="location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            customClass={errors.location ? "is-invalid" : ""}
                          />
                          {errors.location && (
                            <div className="text-danger font-13">
                              {errors.location}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            placeholder={`${t("company_name")}*`}
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            customClass={
                              errors.company_name ? "is-invalid" : ""
                            }
                          />
                          {errors.company_name && (
                            <div className="text-danger font-13">
                              {errors.company_name}
                            </div>
                          )}
                        </div>
                        <div className="col-12 col-sm-12">
                          <InputField
                            placeholder={t("designation")}
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            customClass={errors.designation ? "is-invalid" : ""}
                          />
                          {errors.designation && (
                            <div className="text-danger font-13">
                              {errors.designation}
                            </div>
                          )}
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-car-front me-2"></i>{" "}
                        <span>{t("vehicle_details")}</span>
                      </h5>
                      {rows.map((row, index) => (
                        <div
                          className="d-flex flex-column flex-md-row justify-content-center align-items-start gap-3 mb-3"
                          key={index}
                        >
                          <div className="col-10 col-sm-11 col-md-11">
                            <div className="row g-3">
                              <div className="col-12 col-sm-3">
                                <InputGroup className="">
                                  <Button
                                    variant="outline-secondary"
                                    className="h-100"
                                    onClick={() => decrement(index)}
                                  >
                                    <i className="bi bi-dash"></i>
                                  </Button>
                                  <FormControl
                                    type="text"
                                    readOnly
                                    name="quantity"
                                    value={row.quantity}
                                    onChange={(e) => handleRowChange(index, e)}
                                    min="1"
                                    className="font-13 text-center"
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    onClick={() => increment(index)}
                                  >
                                    <i className="bi bi-plus"></i>
                                  </Button>
                                </InputGroup>
                              </div>
                              <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("make")}*`}
                                  name="car_make_id"
                                  value={row.car_make_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carMake?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_make_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_make_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_make_id}
                                    </div>
                                  )}
                              </div>
                              <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("model")}*`}
                                  name="car_model_id"
                                  value={row.car_model_id}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={carModel?.map((make) => ({
                                    value: make.id, // Using the car make ID as the value
                                    label: make.name, // Using the car make name as the label
                                  }))}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.car_model_id
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].car_model_id && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].car_model_id}
                                    </div>
                                  )}
                              </div>
                              <div className="col-12 col-sm-3">
                                <InputField
                                  fieldType="select"
                                  selectOption={`${t("year")}*`}
                                  name="year"
                                  value={row.year}
                                  onChange={(e) => handleRowChange(index, e)}
                                  options={[
                                    { value: "2020", label: "2020" },
                                    { value: "2021", label: "2021" },
                                    { value: "2022", label: "2022" },
                                    { value: "2023", label: "2023" },
                                    { value: "2024", label: "2024" },
                                  ]}
                                  customClass={
                                    errors?.rowErrors &&
                                    errors.rowErrors[index]?.year
                                      ? "form-select is-invalid"
                                      : "form-select"
                                  }
                                />
                                {errors.rowErrors &&
                                  errors.rowErrors[index] &&
                                  errors.rowErrors[index].year && (
                                    <div className="text-danger font-13">
                                      {errors.rowErrors[index].year}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          {rows.length > 1 && (
                            <div className="col-2 col-sm-1 col-md-1 d-flex align-items-end">
                              <button
                                type="button"
                                className="btn btn-danger w-100"
                                onClick={() => removeRow(index)}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}

                      <div className="text-end mb-3">
                        <div className="row g-3">
                          <div className="col-10 col-sm-11 col-md-11"></div>
                          <div className="col-2 col-sm-1 col-md-1 ">
                            <button
                              type="button"
                              className="btn btn-primary w-100"
                              onClick={addRow}
                            >
                              <i className="bi bi-plus-lg"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="interior_color"
                            value={formData.interior_color}
                            onChange={handleChange}
                            placeholder={t("interior_color")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="exterior_color"
                            value={formData.exterior_color}
                            onChange={handleChange}
                            placeholder={t("exterior_color")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <DatePicker
                            selected={formData.product_date_time}
                            onChange={handleProductionDateChange}
                            showTimeSelect
                            dateFormat="Pp"
                            className="form-control font-13 w-100 h-100"
                            placeholderText={t("prod_date")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="engine_size"
                            value={formData.engine_size}
                            onChange={handleChange}
                            placeholder={t("eng_size")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            name="fuel_type"
                            value={formData.fuel_type}
                            onChange={handleChange}
                            customClass="form-select"
                            selectOption={t("fuel_type")}
                            options={[
                              { value: "Petrol", label: "Petrol" },
                              { value: "Diesel", label: "Diesel" },
                              { value: "Electric", label: "Electric" },
                              { value: "Hybrid", label: "Hybrid" },
                            ]}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            fieldType="select"
                            name="steering"
                            value={formData.steering}
                            onChange={handleChange}
                            customClass="form-select"
                            selectOption={t("steering")}
                            options={[
                              { value: "LHD", label: "Left Hand Drive" },
                              { value: "RHD", label: "Right Hand Drive" },
                            ]}
                          />
                        </div>
                      </div>
                      <h5 className="text-start mb-3 d-flex justify-content-start align-items-center">
                        <i className="bi bi-cart me-2"></i>{" "}
                        <span>{t("offer_details")}</span>
                      </h5>
                      <div className="row g-3 mb-4 text-start">
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="asking_price"
                            value={formData.asking_price}
                            onChange={handleChange}
                            placeholder={t("asking_price")}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <InputField
                            name="sale_method"
                            value={formData.sale_method}
                            onChange={handleChange}
                            placeholder={t("sale_method")}
                          />
                        </div>
                        <div className="col-12 col-sm-12">
                          <InputField
                            fieldType="textarea"
                            name="comment"
                            value={formData.comment}
                            onChange={handleChange}
                            placeholder={t("comments")}
                          />
                        </div>
                      </div>
                      <div className="row g-3 text-center">
                        <div className="col-12 col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                          >
                            {t("submit")}
                          </button>
                          <p className="m-0 text-muted font-15">
                            {t("respond_time")}
                          </p>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>

              {/* <!-- New Section: Want to Partner with Us? Offer Your Vehicles with Ease --> */}
              <div class="container-text">
                <div class="fade-container">
                  <div class="scrollable-section">
                    <div class="scrollable-content ms-2">
                      <div class="row">
                        {/* <!-- Offer Vehicle Section --> */}
                        <div class="col-12 mb-2 pb-2">
                          <h3 class="fw-bold">Want to Partner with Us?</h3>
                          <h4 class="fw-bold">
                            Offer Your Vehicles with Ease.
                          </h4>
                          <p>
                            Do you have vehicles to sell or a business looking
                            to partner on fleet solutions? We're always on the
                            lookout for high-quality vehicles, and partnering
                            with us couldn’t be simpler. By providing just a few
                            details, you can unlock a mutually beneficial
                            relationship with a trusted partner in the
                            automotive industry.
                          </p>

                          <h4 class="fw-bold">How to Offer Your Vehicles:</h4>
                          <h5 class="fw-bold">
                            Step 1: Fill Out the Offer Form
                          </h5>
                          <p>
                            Start by providing essential information about the
                            vehicle(s) you want to offer. This includes make,
                            model, year, and any relevant details such as
                            mileage or condition. We also ask for your business
                            details so we can understand your needs and create a
                            tailored partnership.
                          </p>

                          <h5 class="fw-bold">Step 2: We Review and Respond</h5>
                          <p>
                            Once we receive your submission, our team will
                            carefully review your offer. We assess the vehicle
                            details and business information to determine how we
                            can best work together. We’ll then reach out with a
                            proposal or any follow-up questions.
                          </p>

                          <h5 class="fw-bold">Step 3: Finalize the Deal</h5>
                          <p>
                            After we’ve reviewed everything, we’ll work with you
                            to finalize terms that benefit both parties. Whether
                            it’s a one-time vehicle offer or a long-term
                            collaboration, we’re committed to making the process
                            smooth and rewarding.
                          </p>

                          <h4 class="fw-bold">
                            Why Offer Your Vehicles to Us?
                          </h4>
                          <ul>
                            <li>
                              <strong>Simple and Straightforward:</strong> Our
                              offer form is quick and easy to complete, with no
                              complex paperwork or waiting periods. In just a
                              few minutes, you’re on your way to partnering with
                              a reputable buyer.
                            </li>
                            <li>
                              <strong>Fair Market Value:</strong> We ensure
                              competitive pricing based on the current market,
                              so you know you’re getting a fair deal for your
                              vehicles. Plus, you can trust us for
                              transparency—no hidden fees, no surprises.
                            </li>
                            <li>
                              <strong>Flexible Partnerships:</strong> Whether
                              you’re a dealership, fleet owner, or independent
                              seller, we can tailor our partnership to fit your
                              business needs. We’re open to short-term or
                              long-term collaborations that work best for you.
                            </li>
                            <li>
                              <strong>Fast Responses:</strong> Once you submit
                              your offer, you won’t be left waiting. Our team is
                              dedicated to providing prompt feedback, so you can
                              move forward with your sale or partnership without
                              delays.
                            </li>
                            <li>
                              <strong>Trusted Expertise:</strong> With years of
                              experience in the automotive industry, we’re
                              well-equipped to handle every aspect of the
                              process. We value your business and strive to
                              build lasting, reliable relationships.
                            </li>
                          </ul>

                          <h4 class="fw-bold">Ready to Submit Your Offer?</h4>
                          <p>
                            If you’re ready to take the next step, simply fill
                            out our Vehicle Offer Form today. Whether you’re
                            looking to sell one vehicle or many, we’re here to
                            make the process smooth, profitable, and
                            hassle-free. Submit your details now, and let’s
                            explore how we can work together.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OfferVehicle;
