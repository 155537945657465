import "./SalesMen.css";

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction, getBrandList } from "../redux/auth/authActions";
import { Link, Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import ProductGrid from "../components/ProductGrid";
import thumbnail from "../assets/images/blue-logo.png";
import HomeFilterBanner from "../components/HomeFilterBanner";
import PrimaryHeader from "../components/PrimaryHeader";
import { API_URL } from "../api/authApi";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
const BrandList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const api_url = API_URL.replace("/api", "");
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const btnText = "Download PDF";
  const [isLoading, setIsLoading] = useState(true);
  const [brands, setBrands] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(getBrandList());
      console.log(response?.data);

      setBrands(response?.data);
      //   setProducts(response?.data?.data);
      //   setTotalPages(Math.ceil(response?.data?.total / itemsPerPage));
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    reduxState?.allBrands ? setBrands(reduxState?.allBrands) : fetchData();
    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <PrimaryHeader /> : <PrimaryMobileHeader />}
      {isMobile && <HomeFilterBanner />}
      <div className="container">
        <div className="text-center pt-3 pb-3 pt-sm-5 pb-sm-5">
          <h4 className="text-uppercase fw-bold">{t("all_brands")}</h4>
        </div>
        <div className="row justify-content-center mb-5 pb-5">
          <div className="col-12 col-sm-12">
            <div className="row g-3">
              {brands?.map((brand, index) => (
                <div className="col-4 col-sm-3 col-lg-2" key={index}>
                  <Link
                    to={{
                      pathname: "/car/listing",
                      search: `?make=${brand?.slug}`, // Add the 'make' parameter in the query string
                      state: {
                        id: brand?.id,
                        name: brand?.name,
                        slug: brand?.slug,
                      },
                    }}
                    className="d-block"
                  >
                    <div
                      className="image-container rounded"
                      style={{
                        height: "150px", // Fixed height for the container
                        width: "100%", // Full width of the container
                        overflow: "hidden", // Hide the overflow if the image is larger
                        display: "flex", // Center the image inside the container
                        alignItems: "center", // Vertically center the image
                        justifyContent: "center", // Horizontally center the image
                        backgroundColor: "#fff", // Optional: Background color for smaller images
                      }}
                    >
                      <img
                        src={
                          brand?.logo?.path
                            ? `${api_url}/${brand.logo.path}` // Dynamically use the api_url
                            : thumbnail // Fallback to thumbnail if logo path is not present
                        }
                        alt={brand?.name}
                        className="img-fluid"
                        style={{
                          maxHeight: "100%", // Keep the height within container
                          maxWidth: "100%", // Keep the width within container
                          objectFit: "contain", // Ensures that the entire image fits within the container without being stretched
                        }}
                      />
                    </div>
                  </Link>
                  {/* <div className="brand-name mt-2 d-flex justify-content-center align-items-center">
                    {brand.name}
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BrandList;
