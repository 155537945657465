import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../components/CartDetails.css";
import { Flex } from "antd";
import Link from "antd/es/typography/Link";
import { API_URL } from "../api/authApi";
const CardDetails = ({ cart, updateCart }) => {
  const [isMobile, setIsMobile] = useState(false);
  const api_url = API_URL.replace("/api", "");
  const [items, setItems] = useState([]);
  // const handleRemoveItem = (id) => {
  //   setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  // };

  const totalPrice = items.reduce((total, item) => total + item.price, 0); // Including shipping
  const subtotal = items.reduce((total, item) => total + item.price, 0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    if (cart?.length > 0) {
      setItems(cart); // Update items when cart is updated
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [cart, items]);
  const handleQuantityChange = (product_id, quantity) => {
    // Trigger updateCart when quantity changes
    updateCart({
      product_id,
      quantity,
      action: "add", // default action for quantity change
    });
  };
  const handleAddItem = (product_id, currentQuantity) => {
    // Increase the quantity by 1 and trigger updateCart
    const newQuantity = currentQuantity + 1;
    updateCart({
      product_id,
      quantity: newQuantity,
      action: "add",
    });
  };

  const handleRemoveItem = (product_id, currentQuantity) => {
    // Decrease the quantity by 1 and trigger updateCart
    const newQuantity = currentQuantity > 1 ? currentQuantity - 1 : 1; // Ensure at least 1
    updateCart({
      product_id,
      quantity: newQuantity,
      action: "remove",
    });
  };
  return (
    <div className="container" style={{ marginBottom: "30px" }}>
      <div
        className="line"
        style={{ height: "1px", backgroundColor: "#ccc", margin: "20px 0" }}
      />

      <div
        style={{
          display: "flex",
          gap: "10px",
          fontSize: "12.5px",
        }}
      >
        <span style={{ opacity: "0.3" }}>Home</span>
        <span>/</span>
        <span>Cart</span>
      </div>

      <div
        className="table-responsive"
        style={{
          marginTop: "24px",
          maxWidth: "1170px",
          margin: "auto",
          height: "696px",
        }}
      >
        <div>
          <table
            className="bg-white"
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0 32px",
            }}
          >
            <thead
              className="shadow-sm p-3 mb-5 bg-white rounded"
              style={{
                top: "10px",
                backgroundColor: "#F3F3F3",
                height: "72px",
              }}
            >
              <tr
                style={{
                  justifyContent: "center",
                  marginTop: "24px",
                  width: "1091px",
                  height: "24px",
                  left: "40px",
                  fontSize: "16px",
                }}
              >
                <td
                  className="px-4 py-2"
                  style={{ alignItems: "center", alignContent: "center" }}
                >
                  <b>Product </b>
                </td>
                <td
                  className="px-4 py-2"
                  style={{ alignItems: "center", alignContent: "center" }}
                >
                  <b>Price</b>
                </td>
                <td
                  className="px-4 py-2"
                  style={{ alignItems: "center", alignContent: "center" }}
                >
                  <b>Quantity</b>
                </td>
                <td
                  className="px-4 py-2"
                  style={{ alignItems: "center", alignContent: "center" }}
                >
                  <b>Subtotal</b>
                </td>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr
                  key={item?.product?.id}
                  className="shadow-sm p-3 mb-5 bg-white rounded"
                  style={{
                    alignItems: "center",
                    borderRadius: "4px",
                    height: "102px",
                  }}
                >
                  <td className="px-3 py-2">
                    <div style={{ position: "relative" }}>
                      <img
                        src={`${api_url}/${item?.product?.images[0]?.image?.path}`}
                        className="img-fluid rounded-3 me-3"
                        alt={item?.product?.name}
                        style={{ width: "54px", height: "54px" }}
                      />
                      <i
                        className="bi bi-x-circle-fill"
                        onClick={() => handleRemoveItem(item?.id)}
                        style={{
                          position: "absolute",
                          top: "0px",
                          left: "0px",
                          display: "flex",
                          borderRadius: "50%",
                          cursor: "pointer",
                          color: "black",
                          background: "white",
                          borderRadius: "99%",
                          fontSize: "18px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      />
                      <span>{item?.product?.name}</span>
                    </div>
                  </td>
                  <td>{item?.product?.price?.toFixed(2)} AED</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <button
                        className="btn btn-link px-2"
                        onClick={() =>
                          handleAddItem(item?.product?.id, item?.quantity)
                        }
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <input
                        type="number"
                        className="form-control form-control-sm mx-2"
                        value={item?.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item?.product?.id,
                            parseInt(e.target.value)
                          )
                        }
                        style={{ width: "72px", height: "42px" }}
                      />
                      <button
                        className="btn btn-link px-2"
                        onClick={() =>
                          handleAddItem(item?.product?.id, item?.quantity)
                        }
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                    {/* <div className="d-flex align-items-center">
                      <button
                        className="btn btn-link px-2"
                        onClick={() => console.log("Decrease quantity")}
                      >
                        <i className="fas fa-minus"></i>
                      </button>
                      <input
                        id={`quantity-${item?.id}`}
                        min="1"
                        name="quantity"
                        defaultValue={item?.quantity}
                        type="number"
                        className="form-control form-control-sm mx-2"
                        style={{
                          width: "72px",
                          height: "42px",
                          backgroundColor: "white",
                        }}
                      />
                      <button
                        className="btn btn-link px-2"
                        onClick={() => console.log("Increase quantity")}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div> */}
                  </td>
                  <td>
                    {(item?.product?.price * item?.quantity)?.toFixed(2)} AED
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            background: "#F3F3F3",
            display: "flex",
            float: "right",
            marginRight: "0px",
            height: "290px",
            padding: "20px",
            borderRadius: "8px",
            marginTop: "20px",
          }}
        >
          <Container>
            <h1
              style={{
                color: "#000000",
                fontSize: "20px",
                marginTop: "25px",
                marginBottom: "25px",
                fontWeight: "500",
              }}
            >
              <b>Cart Total</b>
            </h1>
            <Row>
              <Col>
                <Row className="mb-3">
                  <Col>
                    <h5 className="text-uppercase">Subtotal</h5>
                  </Col>
                  <Col className="text-end">
                    <h5>
                      {items
                        .reduce(
                          (total, item) =>
                            total + item?.product?.price * item?.quantity,
                          0
                        )
                        .toFixed(2)}{" "}
                      AED
                    </h5>
                  </Col>
                </Row>

                <Row className="mb-4">
                  <Col>
                    <h5 className="text-uppercase">Total price</h5>
                  </Col>
                  <Col className="text-end">
                    <h5>
                      {items
                        .reduce(
                          (total, item) =>
                            total + item?.product?.price * item?.quantity,
                          0
                        )
                        .toFixed(2)}{" "}
                      AED
                    </h5>
                  </Col>
                </Row>

                <button
                  type="button"
                  className="btn btn-primary btn-lg w-100 mt-1"
                >
                  Proceed to checkout
                </button>

                <Link
                  className="text-body text-decoration-underline mt-2"
                  to="/terms-and-condition"
                >
                  Terms and Conditions
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
