// import "./ServicePage.css";
import "./Logistics.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  getCarMake,
  getCarModel,
  getCertificates,
  documentationForm,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import "react-phone-input-2/lib/style.css";
import banner from "../assets/images/make-a-deal.png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";
import "../pages/DocAndCertificates.css";
import { right } from "@popperjs/core";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { Button, message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const ServicePage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [refId, setRefId] = useState("");
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const [selectedCarMake, setSelectedCarMake] = useState("");
  const [selectedCarModel, setSelectedCarModel] = useState("");
  const [certificatesPrices, setCertificatePrices] = useState([]);
  const reduxState = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    car_make_id: "",
    car_model_id: "",
    year: "",
    vin: "",
    comments: "",
    service_items: [],
    attachments: [],
    certificates: {
      RTACertificates: false,
      TrafficCertificates: false,
      AbcCertificatess: false,
      AbcdCertificates: false,
      RTACertificates: false,
    },
  });
  // Inside your ServicePage component
  const navigate = useNavigate();

  const certificatePrices = {
    RTACertificates: 190,
    TrafficCertificates: 299,
    AbcCertificatess: 99,
    AbcdCertificates: 410,
  };

  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };
  const fetchCertificates = async () => {
    const response = await dispatch(getCertificates());
    console.log("cccccccccc", response?.data);
    setCertificatePrices(response?.data);

    // setCarModel(response?.data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handleChange:", name, "...", value);

    if (name === "car_make_id") {
      const selectedBrand = carMake?.find(
        (brand) => brand?.id === parseInt(value)
      );
      if (selectedBrand) {
        setSelectedCarMake(selectedBrand?.name);
        const brandSlug = selectedBrand.slug;
        console.log("Brand Slug:", brandSlug); // You can remove this line after testing
        fetchCarModel(brandSlug); // Call fetchCarModel with the selected brand id
      } else {
        console.error("Brand not found for the given id");
      }
    }
    if (name === "car_model_id") {
      const selectedModel = carMake?.find(
        (model) => model?.id === parseInt(value)
      );
      selectedModel && setSelectedCarModel(selectedModel?.name);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(documentationForm(formData));
    console.log("document foem:----", response?.data);

    const selectedCertificateIds = formData.service_items;

    // Create an array of certificates with true/false status based on selection
    const certificatesWithStatus = certificatesPrices.map((certificate) => ({
      ...certificate,
      selected: selectedCertificateIds.includes(certificate.id), // Set selected status to true/false
    }));

    // Extract selected certificates (names only for review)
    const selectedCertificates = certificatesWithStatus
      .filter((cert) => cert.selected) // Only take selected certificates
      .map((cert) => cert.name); // Extract names of selected certificates

    // Calculate subtotal based on selected certificates' costs
    const subtotal = certificatesWithStatus
      .filter((cert) => cert.selected) // Only selected certificates
      .reduce((total, cert) => total + cert.cost, 0); // Sum their costs

    // Assuming the grand total is the same as subtotal (can modify for additional fees/taxes)
    const grandTotal = subtotal;

    // Prepare data to pass to the Review page
    const dataToReview = {
      ...formData, // Include all filled form data
      certificates: certificatesWithStatus, // Pass certificates with their true/false status
      selectedCertificates, // Include only the selected certificates (names)
      subtotal, // Pass the subtotal
      grandTotal, // Pass the grand total
      selectedCarMake, // Car make (assume this is retrieved from formData or elsewhere)
      selectedCarModel, // Car model (assume this is retrieved from formData or elsewhere)
    };

    console.log("Form data submitted:", dataToReview);

    // Navigate to the Review page and pass the data
    navigate("/review", { state: { formData: dataToReview } });
  };

  const handleCheckboxChange = (e, certId) => {
    const { checked } = e.target;

    // Add or remove certificate IDs based on checkbox state
    setFormData((prevData) => {
      const updatedServiceItems = checked
        ? [...prevData.service_items, certId] // Add certificate ID
        : prevData.service_items.filter((id) => id !== certId); // Remove certificate ID if unchecked

      return {
        ...prevData,
        service_items: updatedServiceItems,
      };
    });
  };
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];
  const handleFileUpload = (e, attachmentName) => {
    const file = e.target.files[0]; // Get the uploaded file
    console.log("handleFile");

    if (file) {
      // Check if the uploaded file type is allowed
      if (allowedFileTypes.includes(file.type)) {
        setFormData((prevData) => {
          const updatedAttachments = [...prevData.attachments];

          // Check if an attachment with this name already exists
          const existingAttachmentIndex = updatedAttachments.findIndex(
            (att) => att.name === attachmentName
          );

          if (existingAttachmentIndex !== -1) {
            // If the attachment exists, replace the file
            updatedAttachments[existingAttachmentIndex].file = file;
          } else {
            // If it doesn't exist, add a new attachment object
            updatedAttachments.push({ name: attachmentName, file });
          }
          console.log(updatedAttachments);

          return {
            ...prevData,
            attachments: updatedAttachments,
          };
        });
      } else {
        // Show an error message if the file type is not allowed
        // alert("Invalid file type. Please upload a PDF, Word document, JPEG, or PNG file.");
        message.error(
          `${file.name} is not a valid file type. Only PDF, Word documents, and images are allowed.`
        );
      }
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    fetchCertificates();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12  col-sm-7">
              <img src={banner} alt="Banner" className="img-fluid mb-5" />
              <h2
                className="text-center fw-bold"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                {t("doc_cert")}
              </h2>
              <div className="mt-5 table-responsive-lg">
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-md-8">
                      {/* Left Column */}
                      <div className="row mb-3">
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select"
                            name="car_make_id"
                            value={formData.car_make_id}
                            onChange={handleChange}
                            required
                          >
                            <option value="">{`${t("make")}`}</option>
                            {carMake?.map((make) => (
                              <option key={make.id} value={make.id}>
                                {make.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select"
                            name="car_model_id"
                            value={formData.car_model_id}
                            onChange={handleChange}
                            required
                          >
                            <option value="">{`${t("model")}`}</option>
                            {carModel?.map((make) => (
                              <option key={make.id} value={make.id}>
                                {make.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-4 mb-3">
                          <select
                            className="form-select"
                            name="year"
                            value={formData.year}
                            onChange={handleChange}
                            required
                          >
                            <option value="">{t("year")}</option>
                            <option value="2024">2024</option>
                            <option value="2023">2023</option>
                            <option value="2022">2022</option>
                            <option value="2021">2021</option>
                          </select>
                        </div>
                      </div>

                      <div className="mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="vin"
                          name="vin"
                          value={formData.vin}
                          onChange={handleChange}
                          placeholder= {t("enter_vin")}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <textarea
                          className="form-control"
                          id="comments"
                          name="comments"
                          rows="3"
                          value={formData.comments}
                          onChange={handleChange}
                          placeholder={t("enter_comments")}
                        ></textarea>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100">
                            {t("invoice_upload")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) => handleFileUpload(e, "Invoice")}
                              style={{ display: "none" }}
                            />
                          </label>
                          {formData.attachments.find(
                            (att) => att.name === "Invoice"
                          )?.fileName && (
                            <p className="mt-2">
                              {
                                formData.attachments.find(
                                  (att) => att.name === "Invoice"
                                ).fileName
                              }
                            </p>
                          )}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="btn btn-outline-secondary w-100">
                            {t("export_doc")}
                            <input
                              type="file"
                              accept={allowedFileTypes.join(",")}
                              onChange={(e) =>
                                handleFileUpload(e, "Export Documents")
                              }
                              style={{ display: "none" }}
                            />
                          </label>
                          {formData.attachments.find(
                            (att) => att.name === "Export Documents"
                          )?.fileName && (
                            <p className="mt-2">
                              {
                                formData.attachments.find(
                                  (att) => att.name === "Export Documents"
                                ).fileName
                              }
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Right Column (Certifications) */}
                    <div className="col-md-4">
                      <div className="certifications-container">
                        {certificatesPrices?.map((certificate) => (
                          <div
                            key={certificate.id}
                            className="d-flex align-items-center justify-content-between mb-3"
                          >
                            <div className="certification-box p-3 rounded border w-100 d-flex justify-content-between align-items-center">
                              <label
                                className="form-check-label"
                                htmlFor={certificate.code}
                              >
                                {certificate.name}
                              </label>
                              <span>{certificate.cost} AED</span>
                            </div>
                            <input
                              type="checkbox"
                              id={certificate.code}
                              name={certificate.code}
                              checked={formData.service_items.includes(
                                certificate.id
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(e, certificate.id)
                              }
                              className="form-check-input rounded-circle ms-2"
                              style={{ width: "27px", height: "27px" }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      style={{
                        backgroundColor: "#000740",
                        width: "100%",
                        maxWidth: "267px",
                        height: "52px",
                        marginBottom: "30px",
                        fontSize: "20px",
                      }}
                    >
                      {t("continu_review")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="container-text col-12 col-sm-7">
              {/* Container for the fade effect */}
              <div className="fade-container">
                <div className="scrollable-section">
                  <div className="scrollable-content ms-2">
                    <div className="row">
                      {/* Payment Methods Section */}
                      <div className="col-12 pb-3">
                        <p>
                          We offer a variety of flexible payment options to make
                          your vehicle purchase convenient and secure. Choose
                          the method that works best for you, and rest assured
                          that every transaction is handled with the utmost
                          professionalism and transparency. Explore our
                          available payment methods below:
                        </p>
                        <p>
                          <strong>1. Bank Account Details:</strong> For
                          traditional payments, we provide secure bank account
                          details for easy wire transfers.
                        </p>
                        <p>
                          <strong>2. Letter of Credit:</strong> We accept
                          payments via letter of credit, ensuring a safe and
                          trusted way to process large transactions.
                        </p>
                        <p>
                          <strong>3. Cash Payments:</strong> Prefer cash? We
                          accept cash payments in approved currencies at
                          designated locations.
                        </p>
                        <p>
                          <strong>4. USDT & Cryptocurrency:</strong> For the
                          convenience of modern digital transactions, we accept
                          payments in USDT and other cryptocurrencies. Fast,
                          secure, and global.
                        </p>
                        <p>
                          <em>Note:</em> Read through the specific terms and
                          conditions related to all payment methods before
                          proceeding with your transaction.
                        </p>
                        <p>
                          Click on any option above to learn more and choose the
                          best payment method for your purchase.
                        </p>
                      </div>

                      <div className="col-12">
                        <Link className="theme-color" to="/terms-and-condition">
                          {t("trms_condtn")}
                        </Link>
                      </div>

                      {/* Additional Information Section */}
                      <div className="col-12 mt-4">
                        <p>
                          <strong>Versatile Payment Options:</strong> Explore a
                          variety of payment methods detailed on our website.
                          Each supply contract can be customized to include your
                          preferred payment terms, enhancing convenience and
                          financial planning.
                        </p>
                        <p>
                          <strong>Reliable Delivery Schedules:</strong> We
                          provide precise delivery schedules, including lead
                          times and potential delays, to help you manage your
                          inventory effectively. Trust us to keep you informed
                          every step of the way.
                        </p>
                        <p>
                          <strong>Adaptable Contract Terms:</strong> Cruise
                          Motors offers flexible contract terms with options for
                          fixed-term or rolling agreements, including
                          opportunities for renewal or renegotiation as your
                          business needs evolve.
                        </p>
                        <p>
                          <strong>
                            Detailed Product Specifications and Customization:
                          </strong>{" "}
                          Receive comprehensive details of each car model
                          available for customization. Our dedicated team
                          ensures that your vehicles precisely meet your
                          specifications and branding requirements.
                        </p>
                      </div>

                      <div className="col-12 mt-4">
                        <h4 className="fw-bold">Ready to Get Started?</h4>
                        <p>
                          Obtaining a supply contract with Cruise Motors is the
                          simplest way to ensure a steady, reliable flow of
                          vehicles for your business. Fill out the Supply
                          Contract Application Form today, and let’s start
                          crafting a contract that meets your exact
                          requirements. Your vehicle supply is just a form
                          away—don’t wait, apply now and secure your fleet with
                          Cruise Motors.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div >
            
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ServicePage;
