import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import productImg from "../assets/images/ss4.png";
import mainImg from "../assets/images/Frame 894.png";
import "../styles/ProductDetailsPage.css";
import RelatedItemsCarousel from "../components/RelatedItemsCarousel";
import ShopHeader from "../components/ShopHeader";
import Footer from "../components/Footer";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons"; // Solid heart for wishlist icon
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons"; // Regular heart for unselected
import { getProductDetails, removeWishList } from "../redux/auth/authActions";
import { useLocation } from "react-router-dom";
const ProductDetailsPage = () => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(2);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedImage, setSelectedImage] = useState(productImg);
  const [product, setProduct] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [wishlistAdded, setWishlistAdded] = useState(false);

  const { id, fromPage } = location.state || {};
  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const response = await dispatch(getProductDetails(id));
      setWishlistAdded(response?.data?.is_in_wishlist);
      if (response?.data?.images?.length > 0) {
        setSelectedImage(
          `http://dev-api.cruisemotors.co/${response?.data?.images[0].image?.path}`
        );
      }
      setProduct(response?.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();
    fetchData();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleQuantityChange = (value) => {
    if (quantity + value > 0) {
      setQuantity(quantity + value);
    }
  };
  const relatedItems = [
    {
      id: 1,
      image: productImg,
      title: "Toyota Repair Service Kit in UAE | Car parts price list online",
      price: "120 AED",
      discount: "160 AED",
      discountPercentage: "-40%",
    },
    {
      id: 2,
      image: productImg,
      title: "Toyota Repair Service Kit in UAE | Car parts price list online",
      price: "120 AED",
      discount: "160 AED",
      discountPercentage: "-40%",
    },
    {
      id: 3,
      image: productImg,
      title: "Toyota Repair Service Kit in UAE | Car parts price list online",
      price: "120 AED",
      discount: "160 AED",
      discountPercentage: "-40%",
    },
    {
      id: 4,
      image: productImg,
      title: "Toyota Repair Service Kit in UAE | Car parts price list online",
      price: "120 AED",
      discount: "160 AED",
      discountPercentage: "-40%",
    },
  ];

  const productImages = [
    mainImg,
    productImg, // You can add more unique images here
    productImg,
    productImg,
  ];
  const toggleWishlist = async () => {
    await dispatch(removeWishList(id)); // Toggle wishlist state
    fetchData();
  };

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <ShopHeader />
        {/* Breadcrumb */}
        <nav className="breadcrumb">
          <a href="/">Home</a> / <a href="/service-kits">Service Kits</a> /{" "}
          <span>{product?.name}</span>
        </nav>

        <div className="product-content">
          {/* Left: Image gallery */}
          <div className="image-gallery col-7">
            <div className="thumbnail-images">
              {product?.images?.map((thumb, index) => (
                <img
                  key={index}
                  // Construct the full image path using the base URL and the path from the image object
                  src={`http://dev-api.cruisemotors.co/${thumb.image.path}`}
                  alt={`Thumbnail ${index + 1}`}
                  className={`thumbnail ${
                    selectedImage ===
                    `http://dev-api.cruisemotors.co/${thumb.image.path}`
                      ? "active"
                      : ""
                  }`} // Optional active class for styling
                  onClick={() =>
                    setSelectedImage(
                      `http://dev-api.cruisemotors.co/${thumb.image.path}`
                    )
                  } // Update selected image on click
                />
              ))}
            </div>
            <div className="main-image">
              <img
                src={selectedImage} // Dynamically update the main image
                alt="Main Product"
                className="main-img"
              />
            </div>
          </div>

          {/* Right: Product info */}
          <div className="product-info">
            <h1>{product?.name}</h1>
            <p className="product-price">{product.price} AED</p>
            <p className="product-description">{product?.short_description}</p>

            {/* Quantity selector */}
            <div className="product-details-buttons">
              <div className="quantity-selector">
                <button onClick={() => handleQuantityChange(-1)}>-</button>
                <span>{quantity}</span>
                <button onClick={() => handleQuantityChange(1)}>+</button>
              </div>

              <button className="buy-now-btn">Add To Cart</button>

              <button
                className="wishlist-icon"
                onClick={toggleWishlist}
                style={{ height: "40px", width: "40px" }}
              >
                <FontAwesomeIcon
                  icon={wishlistAdded ? solidHeart : regularHeart}
                  style={{ height: "30px", width: "26px" }}
                />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: product.long_description }}
          ></div>
          {/* Related Items Section */}
          <div className="mt-2 ml-2">
            <h3>Related Items</h3>
          </div>
          <RelatedItemsCarousel items={relatedItems} currentPage={"HomePage"}/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
