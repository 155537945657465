import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../components/CartFilter.css"; // You can create this CSS file for styling

const ShopHeader = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="shop-header mb-3 mt-3">
      <div className="shop-search-bar">
        <input type="text" placeholder="What are you looking for?" />
        <i className="bi bi-search shop-search-icon"></i>
      </div>
      <div className="shop-header-icons">
        <Link to="/wishlist" className="shop-header-icon">
          <i className="bi bi-heart"></i>
        </Link>
        <Link to="/cart" className="shop-header-icon cart-icon">
          <i className="bi bi-cart" ></i>
          <span className="cart-badge">2</span>
        </Link>
        {!isMobile }
      </div>
    </div>
  );
};

export default ShopHeader;
