import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  submitSupplyContractForm,
  updateProfile,
} from "../redux/auth/authActions";
import { Navigate, Link, Router, Route, Routes } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload, Image, Modal } from "antd";

import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

import banner from "../assets/images/warranty-information.png";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import UserDashboardSideMenu from "../components/UserDashboardSideMenu";
import InputField from "../components/InputField";
import { API_URL } from "../api/authApi";
const MyAccount = () => {
  const [fileList, setFileList] = useState([]); // Initialize fileList as an array
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [userData, setUserData] = useState(user?.data?.user) // Control modal visibility
  const [previewImage, setPreviewImage] = useState(""); // Store the image for preview
  const [previewTitle, setPreviewTitle] = useState(""); // Store the image title

  const profileImagePath = userData?.profile_image?.path;
  const dispatch = useDispatch();
  const api_url = API_URL.replace("/api", "");
  ;
  console.log("userrrr:", userData);
  const [isMobile, setIsMobile] = useState(false);
  const [formData, setFormData] = useState({
    company_name: "",
    person_name: "",
    person_email: "",
    mobile: "",
    person_designation: "",
    company_address: "",
  });

  const [uploadedFiles, setUploadedFiles] = useState({
    tradeLicense: null,
    vehicleSpecs: null,
    purchaseOrder: null,
    letterOfIntent: null,
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "image/jpeg",
    "image/png",
  ];

  const handleUploadImage = (info) => {
    console.log(info);

    let updatedFileList = [...info.fileList].slice(-1); // Limit to one file
    setFileList(updatedFileList); // Update fileList

    // Handle file status changes (optional)
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url); // Set the image URL for preview
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewVisible(true); // Show the modal
  };

  const handleCancel = () => setPreviewVisible(false); // Hide the modal

  const validate = () => {
    let formErrors = {};

    if (!formData.company_name)
      formErrors.company_name = "Company name is required";
    if (!formData.person_name)
      formErrors.person_name = "Person name is required";
    if (!formData.person_email) formErrors.person_email = "Email is required";
    if (formData.person_email && !/\S+@\S+\.\S+/.test(formData.person_email)) {
      formErrors.person_email = "Email is invalid";
    }
    if (!formData.mobile) formErrors.mobile = "Mobile number is required";
    if (!formData.person_designation)
      formErrors.person_designation = "Person designation is required";
    if (!formData.company_address)
      formErrors.company_address = "Company address is required";

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });
  };
  const beforeUploadUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE; // Prevent uploading invalid file
    }

    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE; // Prevent uploading oversized file
    }

    // Append the valid file to the state
    setFileList([file]);
    return false; // Prevent automatic upload by returning false
  };

  const handleSubmit = async (e) => {
    console.log("kkkkkk");

    e.preventDefault();
    const formErrors = validate();
    console.log(formErrors);

    // if (Object.keys(formErrors).length > 0) {
    //   setErrors(formErrors);
    //   return;
    // }

    const data = new FormData(); // Create FormData object
    formData?.company_name &&
      data.append("company_name", formData?.company_name);
    formData?.person_name && data.append("person_name", formData?.person_name);
    formData?.person_email &&
      data.append("person_email", formData?.person_email);
    formData?.mobile && data.append("person_phone", formData?.mobile);
    formData?.person_designation &&
      data.append("person_designation", formData?.person_designation);
    formData?.company_address &&
      data.append("company_address", formData?.company_address);
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj; // Get the original file object
      userData?.company
        ? data.append("company_logo", file)
        : data.append("profile_image", file);
    }
    const response = await dispatch(updateProfile(data));
    console.log(response?.data);
    setUserData(response?.data);
    if (response?.code == 200) {
      message.success("Submitted successfully!");
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);
    if (userData) {
      // If user is a company, fill both user and company details
      if (userData?.company) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: userData.company.name || "", // Fill company name
          company_address: userData.company.address || "", // Fill company address
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      } else {
        // If user is not a company, fill only personal details
        setFormData((prevData) => ({
          ...prevData,
          person_name: userData.name || "", // Fill person name from userData
          person_email: userData.email || "", // Fill person email from userData
          mobile: userData.phone || "", // Fill person phone from userData
          person_designation: userData.designation || "", // Fill person designation
        }));
      }
      if (profileImagePath) {
        setFileList([
          {
            uid: "-1", // Unique ID for the file
            name: userData?.profile_image?.original_name || "Profile Image", // Image name
            status: "done", // Set status to done to mark it as uploaded
            url: `${api_url}/${profileImagePath}`, // Full path to the image
          },
        ]);
      }
    }

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-md-3 mb-4">
              <UserDashboardSideMenu />
            </div>
            <div className="col-12 col-md-9">
              <div className="card bg-white border-0 rounded-10">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="card rounded-10 bg-white border-0 mb-5">
                      <div className="card-body p-3 p-md-5">
                        <h4 className="mb-4 text-center text-md-left">
                          My Personal Details
                        </h4>

                        <div className="row">
                          <div className="col-12 col-sm-4 col-md-3 mb-3">
                            {/* Profile Image Upload */}
                            <Upload
                              listType="picture-card"
                              maxCount={1}
                              fileList={fileList}
                              onChange={handleUploadImage}
                              beforeUpload={beforeUploadUpload}
                              onPreview={handlePreview}
                            >
                              {fileList.length >= 1 ? null : uploadButton}
                            </Upload>

                            {/* Modal for previewing the image */}
                            <Modal
                              visible={previewVisible}
                              title={previewTitle}
                              footer={null}
                              onCancel={handleCancel}
                            >
                              <img
                                alt="preview"
                                style={{ width: "100%" }}
                                src={previewImage}
                              />
                            </Modal>
                          </div>

                          <div className="col-12 col-sm-8 col-md-9">
                            {/* Person & Company Details */}
                            <div className="row g-3 mb-4 text-start">
                              <div className="col-12 col-sm-6">
                                <InputField
                                  placeholder="Person Name *"
                                  type="text"
                                  name="person_name"
                                  value={formData.person_name}
                                  onChange={handleChange}
                                  customClass={
                                    errors.person_name ? "is-invalid" : ""
                                  }
                                />
                                {errors.person_name && (
                                  <div className="text-danger font-13">
                                    {errors.person_name}
                                  </div>
                                )}
                              </div>

                              <div className="col-12 col-sm-6">
                                <InputField
                                  placeholder="Person Email *"
                                  type="email"
                                  name="person_email"
                                  value={formData.person_email}
                                  onChange={handleChange}
                                  disabled={true}
                                  customClass={
                                    errors.person_email ? "is-invalid" : ""
                                  }
                                />
                                {errors.person_email && (
                                  <div className="text-danger font-13">
                                    {errors.person_email}
                                  </div>
                                )}
                              </div>

                              <div className="col-12 col-sm-6">
                                <div className="text-start">
                                  <PhoneInput
                                    country={"ae"}
                                    excludeCountries={["il"]}
                                    name="mobile"
                                    value={formData.mobile}
                                    className="w-100"
                                    onChange={handlePhoneChange}
                                    inputClass={`form-control ${
                                      errors.mobile ? "is-invalid" : ""
                                    }`}
                                    masks={{ ae: ".. ... ...." }}
                                    enableSearch={true}
                                  />
                                  {errors.mobile && (
                                    <div className="text-danger font-13">
                                      {errors.mobile}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {userData?.company && (
                                <>
                                  <div className="col-12 col-sm-6">
                                    <InputField
                                      placeholder="Person Designation *"
                                      type="text"
                                      name="person_designation"
                                      value={formData.person_designation}
                                      onChange={handleChange}
                                      customClass={
                                        errors.person_designation
                                          ? "is-invalid"
                                          : ""
                                      }
                                    />
                                    {errors.person_designation && (
                                      <div className="text-danger font-13">
                                        {errors.person_designation}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <InputField
                                      placeholder="Company Name *"
                                      type="text"
                                      name="company_name"
                                      value={formData.company_name}
                                      onChange={handleChange}
                                      customClass={
                                        errors.company_name ? "is-invalid" : ""
                                      }
                                    />
                                    {errors.company_name && (
                                      <div className="text-danger font-13">
                                        {errors.company_name}
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 col-sm-6">
                                    <InputField
                                      placeholder="Company Address *"
                                      type="text"
                                      name="company_address"
                                      value={formData.company_address}
                                      onChange={handleChange}
                                      customClass={
                                        errors.company_address
                                          ? "is-invalid"
                                          : ""
                                      }
                                    />
                                    {errors.company_address && (
                                      <div className="text-danger font-13">
                                        {errors.company_address}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row g-3 text-end ">
                          <div className="col-12 d-flex justify-content-center">
                            <button
                              style={{ maxWidth: "300px", width: "100%" }}
                              type="submit"
                              className="btn btn-primary btn-lg w-100 w-md-auto ps-5 pe-5 font-15 mb-3"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyAccount;
