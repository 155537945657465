import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
const PageLoader = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false); 
  const { pathname } = useLocation();

  useEffect(() => {
    setIsLoading(true); 
    window.scrollTo(0, 0);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500); 

    return () => clearTimeout(timeout); 
  }, [pathname]);

  return (
    <>
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center vh-100 position-fixed top-0 start-0 w-100 h-100" style={{ zIndex: 9999, backgroundColor: 'rgba(255,255,255,0.8)' }}>
        {/* Loader Spinner */}
        <RotatingLines
          strokeColor="#000740"
          strokeWidth="4"
          animationDuration="0.75"
          width="96"
          visible={true}
        />

        {/* Loader Logo */}
        <img
          src={loaderLogo}
          alt="Loading..."
          style={{ width: "250px", height: "250px", marginLeft: '20px' }}
        />
      </div>
    )}
    <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
      {children}
    </div>
  </>
    // <>
    //   {isLoading ? (
    //      <div className="d-flex justify-content-center align-items-center vh-100">
    //      {/* <ClipLoader size={50} color={"#123abc"} loading={isLoading} /> */}
    //      <RotatingLines
    //        strokeColor="#000740"
    //        strokeWidth="4"
    //        animationDuration="0.75"
    //        width="96"
    //        visible={true}
    //      />

    //      <img
    //        src={loaderLogo}
    //        style={{ width: "250px", height: "250px" }}
    //      ></img>
    //    </div>
    //   ) : (
    //     children 
    //   )}
    // </>
  );
};

export default PageLoader;
