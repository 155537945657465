import "../components/PrimaryHeader.css";
import React, { useState, useEffect } from "react";
import i18n from "../i18n"; // Import your i18n configuration
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  getCarMake,
  getCarModel,
  cartUpdate,
} from "../redux/auth/authActions";
import { setCurrentLanguage } from "../redux/auth/authActions";
import { Navigate, Link, useNavigate } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import useScrollAnimation from "./useScrollAnimation";
import { useTranslation } from "react-i18next";

const PrimaryHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [carMake, setCarMake] = useState([]);
  const [carModel, setCarModel] = useState([]);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const reduxState = useSelector((state) => state.auth);
  const elementsRef = useScrollAnimation();
  const navigate = useNavigate();

  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [vin, setVin] = useState("");

  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };

  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    setCarModel(response?.data);
  };

  const onChange = (id) => {
    setSelectedMake(id); // Set selected make
    const selectedCarMak = carMake?.find((item)=>parseInt(item.id)===parseInt(id));
    console.log(selectedCarMak);
    fetchCarModel(selectedCarMak?.slug);
  };

  const handleModelChange = (id) => {
    setSelectedModel(id); // Set selected model
  };

  const handleSearch = () => {
    if (selectedMake || vin) {
      console.log(carMake, carModel);

      const makeSlug = carMake.find(
        (make) => make.id === parseInt(selectedMake)
      )?.slug;
      const modelSlug = carModel.find(
        (model) => model.id === parseInt(selectedModel)
      )?.slug;
      console.log(selectedMake, selectedModel);
      console.log(makeSlug, modelSlug);

      const queryParams = new URLSearchParams();
      if (makeSlug) queryParams.append("make", makeSlug);
      if (modelSlug) queryParams.append("model", modelSlug);
      if (vin) queryParams.append("vin", vin);
    //   if (selectedMake) queryParams.append("brand_id", selectedMake);
    //   if (selectedModel) queryParams.append("model_id", selectedModel);
      console.log(`/car/listing?${queryParams.toString()}`);

      // Navigate to the /brand-models route with makeSlug, modelSlug, and vin
      //   navigate(`/car/listing?${queryParams.toString()}`);
      navigate({
        pathname: `/car/listing`,
        search: `?${queryParams.toString()}`,
        state: {
          id: selectedMake, // Pass id (Make ID)
          //   modelId: selectedModel, // Pass model id
        },
      });
    } else {
      // Add some error handling logic if fields are not properly filled
      console.log("Please select a make, model, and enter VIN");
    }
  };

  useEffect(() => {
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);

  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );

  const handleSignOut = () => {
    localStorage.removeItem("authToken");
    dispatch(signOutAction());
  };

  if (!isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.lang = lang;
    setLanguage(lang);
    dispatch(setCurrentLanguage(lang));
  };

  return (
    <>
      <header>
        <div className="overly"></div>
        <div className="topGradient"></div>
        <div className="headerInner">
          <HeaderMenu />
          <div className="container">
            <div className="filter-col m-0">
              <h1
                className="text-white font-50 mb-2"
                ref={(el) => (elementsRef.current[0] = el)}
                data-animation="animate__fadeInDown"
                data-speed="animate__slow"
              >
                {t("drive_your_dream")}
              </h1>
              <span style={{ color: "white" }} className="mb-2">
                {t("header_text").split(".")[0]}.<br />
                {t("header_text").split(".")[1]}
              </span>
              <div
                className="row justify-content-start mt-3"
                ref={(el) => (elementsRef.current[1] = el)}
                data-animation="animate__fadeInUp"
                data-speed="animate__slow"
              >
                <div className="col-12 col-sm-7">
                  <div className="card rounded-10 search-filter-header">
                    <div className="card-body p-2">
                      <div className="row">
                        <div className="col-12 col-sm-9">
                          <div className="row">
                            <div className="col-4 border-end">
                              <select
                                className="form-select w-100"
                                onChange={(e) => onChange(e.target.value)}
                              >
                                <option selected disabled>
                                  {t("make")}
                                </option>
                                {carMake?.map((make) => (
                                  <option key={make?.id} value={make?.id}>
                                    {make?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-4 border-end">
                              <select
                                className="form-select w-100"
                                onChange={(e) =>
                                  handleModelChange(e.target.value)
                                }
                              >
                                <option selected disabled>
                                  {t("model")}
                                </option>
                                {carModel?.map((make) => (
                                  <option key={make?.id} value={make?.id}>
                                    {make?.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-4">
                              <input
                                type="text"
                                className="form-control border-0 w-100"
                                placeholder="VIN"
                                value={vin}
                                onChange={(e) => setVin(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-3 mt-3 mt-sm-0">
                          <button
                            className="btn btn-primary w-100 rounded-10"
                            onClick={handleSearch}
                          >
                            <i className="bi bi-search me-1 font-13"></i>{" "}
                            {t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default PrimaryHeader;
