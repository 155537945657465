import { API_URL } from "../api/authApi";
import imgPlaceholder from "../assets/images/img-placeholder.png";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Badge, Avatar, Card, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { bookCar } from "../redux/auth/authActions";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import React, { useEffect } from "react";

const { Meta } = Card;

const ProductCard = ({ productData, btnText }) => {
  const dispatch = useDispatch();
  const api_url = API_URL.replace("/api", "");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const thumbnailImage = productData?.images?.find(
    (img) => img?.is_thumbnail === 1
  );
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const userData = user?.data?.user || {};
  const thumbUrl = thumbnailImage
    ? `${api_url}/${thumbnailImage?.image?.path}` // Use the path of the thumbnail image
    : imgPlaceholder;
  const setNameCar = () => {
    const {
      steering_type,
      name,
      model,
      engine_size,
      fuel_type,
      transmission,
      ext_color,
      production_year,
    } = productData || {};
    const transmissionLabel =
      productData?.transmission === "Automatic" ? "AT" : "ML";
    const carName = `${steering_type ? `(${steering_type})` : ""} ${
      name ? name : ""
    } ${model ? model : ""} ${engine_size ? engine_size : ""} ${
      fuel_type ? fuel_type : ""
    } ${transmissionLabel ? transmissionLabel : ""} ${
      ext_color ? ext_color : ""
    } - ${production_year ? `MY${production_year}` : ""}`;
    return carName.trim();
  };
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);
  const handleBookCar = (e, id) => {
    e.stopPropagation();
    Swal.fire({
      title: "Ready to Book Your Car?",
      text: "We will reserve the car for you once you confirm.",
      icon: "info", // More friendly icon
      showCancelButton: true,
      confirmButtonColor: "#4e3DBD", // Custom primary color
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Book it!",
      cancelButtonText: "No, Cancel",
      background: "#f7f9fc", // Light background
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await dispatch(bookCar(id));
        console.log(response?.data);

        // if(response?.data)
        // Action to be taken after confirmation
        if (response?.code === 200) {
          console.log(userData);

          const refId = response?.data?.code;
          const email = userData?.email;
          Swal.fire({
            title: "Booked!",
            html: `<p>Your booking has been successfully confirmed. A detailed email has been sent to <strong>${email}</strong>.</p>
  <p>You can use the provided booking code <strong>${refId}</strong> to track the status of your booking at any time.</p>
  <p>We will be in contact with you shortly.</p>
  <p><strong>Thank you for choosing us!</strong></p>`,
            icon: "success",
            confirmButtonColor: "#000740", // Custom primary color
          });
        }
      }
    });
  };
  const handleCardClick = () => {
    console.log("productData", productData?.slug);
    const queryParams = new URLSearchParams();
    queryParams.append("car", productData?.slug);
    const queryString = queryParams.toString();
    if (queryString) {
      navigate(`/car-specification?${queryString}`);
    }
  };

  return (
    <>
      <Badge.Ribbon text="New" placement="start" color="#1677ff">
        <Card
          className="w-100 h-100 text-center rounded-3 border-0 shadow"
          hoverable
          onClick={handleCardClick}
          cover={
            <img
              alt={productData?.name || "Product Image"}
              src={thumbUrl}
              className="rounded-top"
              style={{
                height: "300px", // Responsive height
                objectFit: "cover", // Maintain image ratio without stretching
              }}
            />
          }
          // actions={[
          //   <SettingOutlined key="setting" />,
          //   <EditOutlined key="edit" />,
          //   <EllipsisOutlined key="ellipsis" />,
          // ]}
        >
          <Meta
            //   avatar={<Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />}
            className="mb-2"
            title={setNameCar()}
            // description="This is the description"
          />

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="text-muted font-13 fw-bold">
              {productData?.steering_type}
            </div>
            <div className="text-muted font-13 fw-bold">
              {productData?.production_year}
            </div>
            <div className="text-muted font-13 fw-bold">
              {productData?.ext_color}
            </div>
          </div>
          {!productData?.is_booked ? (
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={(e) => handleBookCar(e, productData?.id)}
            >
              {t("book")}
            </button>
          ) : (
            <button type="button" className="btn btn-primary w-100">
              {t("booked")}: {productData?.booked_code}
            </button>
          )}
        </Card>
      </Badge.Ribbon>
      {/* <div className='card rounded-10'>
            <div className='card-body'>
                <div className='inm-col mb-3'>
                    <img src={image} alt={title} title={title} className='img-fluid rounded-10' />
                </div>
                <button type='button' className='btn btn-primary w-100'><i class="bi bi-cloud-download"></i> Download</button>
            </div>
        </div> */}
    </>
  );
};

export default ProductCard;
