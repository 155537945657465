import "./ServicePage.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import banner from "../assets/images/make-a-deal.png";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import RedirectToHome from "../components/RedirectToHome";

function DocumentPayments() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();
  const { subtotal, grandTotal } = location.state || {
    subtotal: 0,
    grandTotal: 0,
  }; // Default values

  const [formData, setFormData] = useState({
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvc: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Payment submitted:", formData);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="bg-white">
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7 table-responsive" style={{ width: "1038px" }}>
              <img
                src={banner}
                alt="Banner"
                className="img-fluid mb-5"
                style={{ height: "494px", width: "1308px" }}
              />
              <h2
                className="text-center fw-bold"
                style={{ fontSize: "40px", color: "#050B20" }}
              >
                Documentation & Certification
              </h2>
              <div
                className="container mt-5 align-content-center"
                style={{
                  height: "464px",
                  width: "743px",
                  borderRadius: "20px",
                }}
              >
                <div className="container mt-5">
                  <p className="text-end">
                    Sub Total:
                    <span style={{ marginLeft: "100px" }}>{subtotal} AED</span>
                  </p>
                  <hr className="line" />

                  <p className="text-end">
                    Grand Total:
                    <span style={{ marginLeft: "100px" }}>{grandTotal} AED</span>
                  </p>
                  <hr className="line" />
                  <form onSubmit={handleSubmit} className="payment-form" style={{marginTop:"30px"}}>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="cardHolderName"
                        placeholder="Card Holder Name"
                        value={formData.cardHolderName}
                        onChange={handleChange}
                        required
                        className="form-control bg-white"
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        name="cardNumber"
                        placeholder="Card Number"
                        value={formData.cardNumber}
                        onChange={handleChange}
                        required
                        maxLength="16"
                        className="form-control bg-white"
                      />
                    </div>
                    <div className="row mb-3">
                      <div className="col">
                        <input
                          type="text"
                          name="expiryDate"
                          placeholder="Expiry Date (MM/YY)"
                          value={formData.expiryDate}
                          onChange={handleChange}
                          required
                          className="form-control bg-white"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          name="cvc"
                          placeholder="CVC"
                          value={formData.cvc}
                          onChange={handleChange}
                          required
                          maxLength="4"
                          className="form-control bg-white"
                        />
                      </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "20px" }}>
                      <button type="submit" className="btn btn-primary" style={{width:'267px',height:'52px'}}>
                        Pay Now
                      </button>
                      <p style={{ marginTop: "10px", color: "gray" }}>
                        We usually respond between 30-50 minutes ...
                      </p>
                    </div>
                  </form>
                  {/* <p className="text-center mt-3">We usually respond between 30-50 minutes</p> */}
                </div>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  padding: "15px",
                  textAlign: "left",
                  width: "100%",
                  marginBottom: "30px",
                }}
                
              >
                <p
                  style={{
                    fontWeight: "400",
                    lineHeight: "40px",
                    fontSize: "20px",
                  }}
                >
                 Documentation and certificates are integral components in the automotive industry, ensuring the safety, reliability, and legal compliance of vehicles. Documentation for cars includes a range of materials such as user manuals, maintenance records, service logs, and technical guides. These documents provide essential information for car owners, mechanics, and technicians, covering everything from operating instructions to detailed maintenance schedules and repair procedures. Proper documentation helps in keeping track of a vehicle’s history, ensuring that it is maintained according to manufacturer recommendations and preserving its resale value.

Documentation and certificates are integral components in the automotive industry, ensuring the safety, reliability, and legal compliance of vehicles. Documentation for cars includes a range of materials such as user manuals, maintenance records, service logs, and technical guides. These documents provide essential information for car owners, mechanics, and technicians, covering everything from operating instructions to detailed maintenance schedules.
                </p>
                <p
                  style={{
                    fontWeight: "400",
                    lineHeight: "40px",
                    fontSize: "20px",
                  }}
                >

Documentation and certificates are integral components in the automotive industry, ensuring the safety, reliability, and legal compliance of vehicles. Documentation for cars includes a range of materials such as user manuals, maintenance records, service logs, and technical guides. These documents provide essential information for car owners, mechanics, and technicians, covering everything from operating instructions to detailed maintenance schedules.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  <Footer />
    </div>
  );
}

export default DocumentPayments;
