import "../components/Footer.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import googleApp from "../assets/images/google-app.png";
import appleApp from "../assets/images/apple-app.png";
import { getFeatureBrandList } from "../redux/auth/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
const Footer = () => {
  const [brands, setBrands] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const reduxState = useSelector((state) => state.auth);
  const currentYear = new Date().getFullYear(); // Get current year dynamically
  const fetchBrands = async () => {
    let url = "0?featured=0";
    const response = await dispatch(getFeatureBrandList(url));
    console.log(response?.data);

    setBrands(response?.data);
  };
  useEffect(() => {
    reduxState?.featuredBrands
      ? setBrands(reduxState.featuredBrands)
      : fetchBrands();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);
  return (
    <>
      <footer>
        <div className="container">
          <div className="row g-4">
            <div className="col-6 col-sm-3">
              <h5 className="text-white mb-3">{t("company")}</h5>
              <div className="row">
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/about-us"
                  >
                    {t("about_us")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/career"
                  >
                    {t("careers")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/our-team"
                  >
                    {t("our_team")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/contact-us"
                  >
                    {t("contact_us")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <h5 className="text-white mb-3"> {t("our_brands")}</h5>
              <div className="row">
                {/* Split brands array into two halves */}
                {brands
                  ?.slice(0, Math.ceil(brands?.length / 2))
                  ?.map((brand) => (
                    <div className="col-6" key={brand?.id}>
                      <Link
                        className="text-white text-decoration-none font-14"
                        to={`/car/listing?make=${brand?.slug}`} // Dynamic link for each brand
                      >
                        {brand?.name}
                      </Link>
                    </div>
                  ))}

                {brands?.slice(Math.ceil(brands?.length / 2))?.map((brand) => (
                  <div className="col-6" key={brand?.id}>
                    <Link
                      className="text-white text-decoration-none font-14"
                      to={`/car/listing?make=${brand?.slug}`} // Dynamic link for each brand
                    >
                      {brand?.name}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <h5 className="text-white mb-3"> {t("engine_type")}</h5>
              <div className="row">
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    {t("petrol")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    {t("diesel")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    {t("electric")}
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    {t("hybrid")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-3">
              <h5 className="text-white mb-3">{t("our_mobile_app")}</h5>
              <div className="row mb-3">
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    <img src={appleApp} alt="Logo" className="img-fluid" />
                  </Link>
                </div>
                <div className="col-12 pt-2 pb-2">
                  <Link
                    className="text-white text-decoration-none font-14"
                    to="/"
                  >
                    <img src={googleApp} alt="Logo" className="img-fluid" />
                  </Link>
                </div>
              </div>
              <h5 className="text-white mb-3">{t("connect_social_media")}</h5>
              <div>
                <Link to="/" className="text-white">
                  <i class="bi bi-facebook pe-3"></i>
                </Link>
                <Link to="/" className="text-white">
                  <i class="bi bi-youtube ps-3 pe-3"></i>
                </Link>
                <Link to="/" className="text-white">
                  <i class="bi bi-instagram ps-3 pe-3"></i>
                </Link>
                <Link to="/" className="text-white">
                  <i class="bi bi-linkedin ps-3 pe-3"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footerBottom pt-3 pb-3 mt-5">
          <div className="container d-flex justify-content-between">
            <p className="text-white m-0">
              © {currentYear} {t("all_rights")}
            </p>
            <div>
              <div className="me-2">
                <Link
                  className="text-white text-decoration-none font-14 me-3"
                  to="/terms-and-condition"
                >
                  {t("trms_n_condtn")}
                </Link>
              </div>
              <div>
                <Link
                  className="text-white text-decoration-none font-14"
                  to="/privacy-policy"
                >
                  {t("privacy_policy")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
