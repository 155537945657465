import styles from "./HomeFilterBanner.module.css";
import React, { useEffect, useRef, useState } from "react";
import b1 from "../assets/images/b1.png";
import b2 from "../assets/images/b2.png";
import b3 from "../assets/images/b3.png";
import b4 from "../assets/images/b4.png";
import b5 from "../assets/images/b5.png";
import b6 from "../assets/images/b6.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import InputField from "./InputField";
import useScrollAnimation from "./useScrollAnimation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { getCarMake, getCarModel } from "../redux/auth/authActions";
import { Navigate, Link, useNavigate } from "react-router-dom";
const HomeFilterBanner = () => {
  const elementsRef = useScrollAnimation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const reduxState = useSelector((state) => state.auth);
  const [carModel, setCarModel] = useState([]);
  const [carMake, setCarMake] = useState([]);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [vin, setVin] = useState("");
  const fetchCarModel = async (id) => {
    const response = await dispatch(getCarModel(id));
    console.log("modelllll:", response?.data);

    setCarModel(response?.data);
  };
  const fetchCarMake = async () => {
    const response = await dispatch(getCarMake());
    setCarMake(response?.data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handleCahnge:", name);
    if (name === "make") {
      setSelectedMake(value);
      const selectedCarMak = carMake?.find(
        (item) => parseInt(item?.id) === parseInt(value)
      );
      console.log(selectedCarMak);

      fetchCarModel(selectedCarMak?.slug);
    } else if (name === "model") {
      setSelectedModel(value);
    }
  };
  const handleSearch = () => {
    if (selectedMake || vin) {
      console.log(carMake, carModel);
      const makeSlug = carMake.find(
        (make) => make.id === parseInt(selectedMake)
      )?.slug;
      const modelSlug = carModel.find(
        (model) => model.id === parseInt(selectedModel)
      )?.slug;
      const queryParams = new URLSearchParams();
      if (makeSlug) queryParams.append("make", makeSlug);
      if (modelSlug) queryParams.append("model", modelSlug);
      if (vin) queryParams.append("vin", vin);
      navigate({
        pathname: `/car/listing`,
        search: `?${queryParams.toString()}`,
        state: {
          id: selectedMake,
        },
      });
    } else {
      console.log("Please select a make, model, and enter VIN");
    }
  };
  useEffect(() => {
    reduxState?.carMake ? setCarMake(reduxState?.carMake) : fetchCarMake();
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);
  return (
    <>
      <div className="p-2 pt-3">
        <div
          className={`card rounded-20 overflow-hidden text-center  z-0 ${styles.filterBanner}`}
        >
          <div className="overly z-0"></div>
          <div className="card-body pt-3 pb-5 z-1">
            <h1
              className="text-white"
              ref={(el) => (elementsRef.current[0] = el)}
              data-animation="animate__fadeInDown"
            >
              {t("drive_your_dream")}
            </h1>
            <p
              className="text-white fw-light m-0"
              ref={(el) => (elementsRef.current[1] = el)}
              data-animation="animate__fadeInUp"
            >
              {t("with_cm")}
            </p>
          </div>
        </div>
        <div
          className={`p-4 pb-3 pt-0 z-1 ${styles.filterBox}`}
          ref={(el) => (elementsRef.current[2] = el)}
          data-animation="animate__fadeInUp"
        >
          <div className="card rounded-20 shadow">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-6">
                  <InputField
                    fieldType="select"
                    selectOption={t("make")}
                    name="make"
                    customClass="form-select"
                    onChange={handleChange}
                    // value={row.make}
                    // onChange={(e) => handleRowChange(index, e)}
                    options={carMake?.map((make) => ({
                      value: make?.id,
                      label: make?.name,
                    }))}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    fieldType="select"
                    selectOption={t("model")}
                    name="model"
                    customClass="form-select"
                    onChange={handleChange}
                    // value={row.make}
                    // onChange={(e) => handleRowChange(index, e)}
                    options={carModel?.map((make) => ({
                      value: make?.id,
                      label: make?.name,
                    }))}
                  />
                </div>
                <div className="col-12">
                  <InputField
                    selectOption="VIN"
                    name="vin"
                    placeholder="VIN Number"
                    value={vin}
                    onChange={(e) => setVin(e.target.value)}
                    // value={row.make}
                    // onChange={(e) => handleRowChange(index, e)}
                  />
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary w-100 rounded-pill"
                    onClick={handleSearch}
                  >
                    <i class="bi bi-search"></i> {t("search")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFilterBanner;
