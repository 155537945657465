import "./SignInPage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signInAction,
  setCurrentLanguage,
  submitResetPassword,
  submitVerifyOtp,
  submitRequestOtp,
} from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import i18n from "../i18n"; // Import your i18n configuration
import logo from "../assets/images/blue-logo.png"; // Import the image file
import Swal from "sweetalert2";
import { Button, message, Space } from "antd";
import RedirectToHome from "../components/RedirectToHome";
import PhoneInput from "react-phone-input-2";
import { forgotPassword } from "../api/authApi";
import { useNavigate } from "react-router-dom";
// import { height } from '@mui/system';

const Forgotten = () => {
  //   const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [timer, setTimer] = useState(90); // Countdown timer in seconds
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setIsOtpSent(false);
    }
    let interval = null;
    if (isOtpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (!isOtpSent) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isOtpSent, timer]);

  const sendOtp = async () => {
    if (!mobile) {
      setMobileError(t("mobile_required"));
      return;
    } else {
      handleMobileChange(mobile); // Revalidate the mobile number before sending
      if (mobileError) {
        return; // Stop if validation fails
      }
    }

    if (mobileError || !mobile) {
      setMobileError(t("mobile_required"));
      return;
    }

    setLoading(true);
    const response = await dispatch(
      submitRequestOtp({ phone_number: "+" + mobile })
    );

    setLoading(false);
    // setIsOtpSent(true);
    if (response?.code === 200) {
      setIsOtpSent(true);
      setTimer(90); // Reset the timer when OTP is sent
      message.success("Request for an Otp sent successfully!");
    } else {
      message.error("Failed to send OTP!");
    }
  };

  const verifyOtp = async () => {
    const otpRegex = /^[0-9]{6}$/;
    if (!otp) {
      setOtpError(t("otp_required"));
      return;
    } else if (!otpRegex.test(otp)) {
      setOtpError(t("invalid_otp"));
      return;
    }
    const response = await dispatch(
      submitVerifyOtp({ phone_number: "+" + mobile, otp_code: otp })
    );
    if (response?.code === 200) {
      setOtpError("");
      setIsOtpVerified(true);
      message.success("OTP verified successfully!");
    } else {
      message.error("OTP verification failed!");
    }
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value.length >= 6) {
      setNewPasswordError("");
    } else {
      setNewPasswordError(t("password_min_length")); // Add password length error
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value !== newPassword) {
      setConfirmPasswordError(t("passwords_do_not_match")); // Add mismatch error
    } else {
      setConfirmPasswordError("");
    }
  };

  const resetPassword = async () => {
    if (
      newPasswordError ||
      confirmPasswordError ||
      !newPassword ||
      !confirmPassword
    ) {
      return;
    }

    const response = await dispatch(
      submitResetPassword({
        phone_number: "+" + mobile,
        password: newPassword,
        confirm_password: confirmPassword,
      })
    );

    if (response?.code === 200) {
      message.success("Password reset successfully!");

      navigate("/signin");
    } else {
      message.error("Failed to reset password!");
    }
  };

  const handleMobileChange = (value, country) => {
    console.log(value, country);

    setMobile(value);
    const sanitizedFormat = country?.format.replace(/[^\d]/g, "");

    const mobileRegex = /^[0-9]{10,15}$/;
    if (!value) {
      setMobileError(t("mobile_required"));
    } else if (!mobileRegex.test(value)) {
      setMobileError(t("invalid_mobile_format"));
    } else if (country && value.length < sanitizedFormat.length) {
      setMobileError(t("invalid_mobile_length"));
    } else {
      setMobileError("");
    }
  };

  return (
    <>
      <RedirectToHome />
      <div className="login-bg">
        <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
          <div className="login-register vh-100">
            <div className="text-center mb-5">
              <img src={logo} alt="Logo" width="200" />
            </div>
            <div className="card text-center bg-white rounded-20">
              <div className="card-body p-5">
                <h2 className="mb-0 fw-bold theme-color font-26">
                  {isOtpVerified
                    ? t("Reset Your Password")
                    : t("frgt_password")}
                </h2>
                <p className="fw-bold theme-color font-12">
                  {isOtpVerified
                    ? t("enter_new_password")
                    : isOtpSent
                    ? t("enter_otp")
                    : t("frgt_password_text")}
                </p>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    isOtpVerified
                      ? resetPassword()
                      : isOtpSent
                      ? verifyOtp()
                      : sendOtp();
                  }}
                  style={{ width: "100%" }}
                >
                  {!isOtpVerified ? (
                    <>
                      {!isOtpSent ? (
                        <div className="mb-3 text-start">
                          <label htmlFor="mobile" className="form-label">
                            {t("mobile")} <span className="text-danger">*</span>
                          </label>
                          <PhoneInput
                            country={"ae"}
                            value={mobile}
                            excludeCountries={["il"]}
                            onChange={handleMobileChange}
                            inputClass={`form-control ${
                              mobileError ? "is-invalid" : ""
                            }`}
                            inputStyle={{
                              width: "100%",
                              height: "53px",
                              borderRadius: "15px",
                            }}
                            masks={{ ae: ".. ... ...." }}
                            enableSearch={true}
                          />
                          {mobileError && (
                            <div className="text-danger font-14 mt-1">
                              {mobileError}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="mb-3 text-start">
                          <label htmlFor="otp" className="form-label">
                            {t("otp")} <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            placeholder={t("otp_placeholder")}
                            className={`form-control ${
                              otpError ? "is-invalid" : ""
                            }`}
                            id="otp"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          {otpError && (
                            <div className="invalid-feedback">{otpError}</div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="mb-3 text-start">
                        <label htmlFor="newPassword" className="form-label">
                          {t("new_password")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          placeholder={t("new_password_placeholder")}
                          className={`form-control ${
                            newPasswordError ? "is-invalid" : ""
                          }`}
                          id="newPassword"
                          value={newPassword}
                          onChange={handlePasswordChange}
                        />
                        {newPasswordError && (
                          <div className="invalid-feedback">
                            {newPasswordError}
                          </div>
                        )}
                      </div>

                      <div className="mb-3 text-start">
                        <label htmlFor="confirmPassword" className="form-label">
                          {t("confirm_password")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          placeholder={t("confirm_password_placeholder")}
                          className={`form-control ${
                            confirmPasswordError ? "is-invalid" : ""
                          }`}
                          id="confirmPassword"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                        {confirmPasswordError && (
                          <div className="invalid-feedback">
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary w-100">
                      {isOtpVerified
                        ? t("reset_password")
                        : isOtpSent
                        ? t("verify_otp")
                        : t("send_otp")}
                    </button>
                  </div>
                  {/* {isOtpSent && !isOtpVerified && (
                    <div className="mb-3 text-center">
                      <span className="text-danger">
                        {t("resend_otp_in")} {timer}s
                      </span>
                    </div>
                  )} */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotten;
