import React, { useEffect, useRef } from "react";
import styles from "./Services.module.css";
import { Link } from "react-router-dom";
// import service1 from "../assets/images/s1.png";
import service1 from "../assets/images/brand-thumbnail.png";
import service2 from "../assets/images/s2.png";
import service3 from "../assets/images/s3.png";
import service4 from "../assets/images/s4.png";
import service5 from "../assets/images/s5.png";
import service6 from "../assets/images/s6.png";

import useScrollAnimation from "./useScrollAnimation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

const Services = () => {
  const elementsRef = useScrollAnimation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [dispatch]);

  return (
    <>
      <div className={`mt-2 mt-sm-5 ${styles.servicesList}`}>
        <h4 className="mb-2">{t("our_services")}</h4>
        <div className="row g-2 g-sm-4">
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[1] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service1}
                        className="img-fluid rounded-10"
                        alt="Service 1"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">{t("brand_heading")}</h5>
                    <p className="text-muted">{t("brands")}</p>
                    <Link
                      to="/brands"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[2] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service2}
                        className="img-fluid rounded-10"
                        alt="Service 2"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">{t("service_heading")}</h5>
                    <p className="text-muted">{t("services")}</p>
                    <Link
                      to="/services"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[3] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service5}
                        className="img-fluid rounded-10"
                        alt="Service 3"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">{t("logistic_heading")}</h5>
                    <p className="text-muted">{t("logistics")}</p>
                    <Link
                      to="/logistics"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[4] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service4}
                        className="img-fluid rounded-10"
                        alt="Service 4"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">{t("modification_heading")}</h5>
                    <p className="text-muted">{t("modifications")}</p>
                    <Link
                      to="/"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[5] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service3}
                        className="img-fluid rounded-10"
                        alt="Service 5"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">
                      {t("payment_method_heading")}
                    </h5>
                    <p className="text-muted">{t("payment_method")}</p>
                    <Link
                      to="/payment-method"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div
              className="card rounded-15 bg-white h-100 d-flex flex-column"
              ref={(el) => (elementsRef.current[6] = el)}
              data-animation="animate__fadeInUp"
            >
              <div className="card-body p-2 p-sm-3 d-flex flex-column justify-content-between">
                <div className="row g-2">
                  <div className="col-4 col-sm-12">
                    <div className="imgCol">
                      <img
                        src={service6}
                        className="img-fluid rounded-10"
                        alt="Service 6"
                      />
                    </div>
                  </div>
                  <div className="col-8 col-sm-12 ps-3">
                    <h5 className="theme-color">{t("make_a_deal_heading")}</h5>
                    <p className="text-muted">{t("make_a_deal")}</p>
                    <Link
                      to="/make-a-deal"
                      className="btn btn-outline-primary btn-lg w-100 font-15 rounded-10"
                    >
                      {t("view")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
