import "./SalesMen.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signOutAction,
  getCarsByBrand,
  bookCar,
} from "../redux/auth/authActions";
import { Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import ProductGrid from "../components/ProductGrid";
import productImg1 from "../assets/images/toyota-1.png";
import productImg2 from "../assets/images/toyota-2.png";
import productImg3 from "../assets/images/toyota-3.png";
import productImg4 from "../assets/images/toyota-4.png";
import productImg5 from "../assets/images/toyota-5.png";
import productImg6 from "../assets/images/toyota-6.png";
import productImg7 from "../assets/images/toyota-7.png";
import productImg8 from "../assets/images/toyota-8.png";
import productImg9 from "../assets/images/toyota-9.png";
import productImg10 from "../assets/images/toyota-10.png";
import productImg11 from "../assets/images/toyota-11.png";
import productImg12 from "../assets/images/toyota-12.png";
import productImg13 from "../assets/images/toyota-13.png";
import productImg14 from "../assets/images/toyota-14.png";
import productImg15 from "../assets/images/toyota-15.png";
import productImg16 from "../assets/images/toyota-16.png";
import modelLogo from "../assets/images/toyota.png";
import SecondaryHeader from "../components/SecondaryHeader";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../api/authApi";
import ClipLoader from "react-spinners/ClipLoader";
import { RotatingLines } from "react-loader-spinner";
import loaderLogo from "../assets/images/CM LOGO DARK BLUE.png";
const BrandModels = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const api_url = API_URL.replace("/api", "");
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const btnText = "Book This Car";
  const searchParams = new URLSearchParams(location?.search);
  const brand_slug = searchParams?.get("make");
  const model_slug = searchParams?.get("model");
  const vin = searchParams?.get("vin");
  const interior_color = searchParams?.get("interiorColor");
  const exterior_color = searchParams?.get("exteriorColor");
  const steering = searchParams?.get("steering");
  const reduxState = useSelector((state) => state.auth);
  const [logo, setLogo] = useState("");
  const [brandName, setBrandName] = useState("");
  // cars?.length > 0 ? `${api_url}/${cars[0]?.car_make?.logo?.path}` : null;
  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  const products = [
    {
      id: 1,
      title: "2024 Toyota Land Cruiser",
      image: productImg1,
      kilometer: "0 KM",
      year: "2024",
      color: "White",
    },
    {
      id: 2,
      title: "2023 Nissan Patrol",
      image: productImg2,
      kilometer: "10,000 KM",
      year: "2023",
      color: "Black",
    },
    {
      id: 3,
      title: "2024 Toyota Land Cruiser",
      image: productImg3,
      kilometer: "0 KM",
      year: "2024",
      color: "White",
    },
    {
      id: 4,
      title: "2021 Modified Jeep Wrangler",
      image: productImg4,
      kilometer: "5,000 KM",
      year: "2021",
      color: "Green",
    },
    {
      id: 5,
      title: "2020 Modified Ford Mustang",
      image: productImg5,
      kilometer: "12,000 KM",
      year: "2020",
      color: "Red",
    },
    {
      id: 6,
      title: "2025 Tesla Model X",
      image: productImg6,
      kilometer: "Brand New",
      year: "2025",
      color: "Blue",
    },
    {
      id: 7,
      title: "2025 Mercedes-Benz S-Class",
      image: productImg7,
      kilometer: "Brand New",
      year: "2025",
      color: "Silver",
    },
  ];
  const fetchCarsByBrand = async (brand_slug) => {
    setIsLoading(true);
    const response = await dispatch(
      getCarsByBrand({ make: brand_slug, model: model_slug, vin: vin, interior_color:interior_color,exterior_color:exterior_color })
    );
    console.log("hvbhvbhb:", response?.data);
    setCars(response?.data?.result?.data);
    setLogo(`${api_url}/${response?.data?.car_make?.logo?.path}`);
    setBrandName(response?.data?.car_make?.name);
    setIsLoading(false);
  };

  const fetchCarsByModel = async (model_slug) => {
    console.log("fetchCarsByModel:", model_slug);

    // const response = await dispatch(getCarsByModel({ make: brand_slug }));
    // console.log("hvbhvbhb:", response?.data);
    // setCars(response?.data);
  };
  useEffect(() => {
    console.log("brandModelsssmfskvnkn");

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    if (brand_slug) {
      fetchCarsByBrand(brand_slug);
    }
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [location.search, navigate]);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            {/* <ClipLoader size={50} color={"#123abc"} loading={isLoading} /> */}
            <RotatingLines
              strokeColor="#000740"
              strokeWidth="4"
              animationDuration="0.75"
              width="96"
              visible={true}
            />

            <img
              src={loaderLogo}
              style={{ width: "250px", height: "250px" }}
            ></img>
          </div>
        ) : (
          <>
            <div className="text-center pt-3 pb-3">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid" // Ensures responsiveness
                style={{ width: "250px", height: "250px" }} // Specific size
              />
              <h4 className="text-uppercase fw-bold mt-1">{brandName}</h4>
            </div>

            <ProductGrid
              products={cars}
              btnText={btnText}
              car_make_slug={brand_slug}
              car_model_slug={model_slug}
              vin={vin}
              interiorColor={interior_color}
              exteriorColor={exterior_color}
              steeringProp={steering}
            />
          </>
        )}
      </div>
      {!isLoading && <Footer />}
    </div>
  );
};

export default BrandModels;
