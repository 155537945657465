import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOutAction } from "../redux/auth/authActions";
import Footer from "../components/Footer";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToSignin from "../components/RedirectToSignin";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SecondaryHeader from "../components/SecondaryHeader";
import { Container, Row, Col } from "react-bootstrap";
import fram1 from "../assets/images/Frame 894.png";
import hondaspare from "../assets/images/b5.png";
import Paymentlogo1 from "../assets/images/paymentlogo1.png";
import Paymentlogo2 from "../assets/images/paymentlogo2.png";
import Paymentlogo3 from "../assets/images/paymentogo3.png";
import Paymentlogo4 from "../assets/images/paymentlogo4.png";
import Paypallogo from "../assets/images/paypallogo.png";
import Link from "antd/es/typography/Link";
import { Button } from "antd";

const Cart = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}

      <Container className="mt-4 pb-5">
        <Row className="justify-content-center">
          <div className="shop-header mb-3 mt-3">
            <div className="shop-search-bar">
              <input type="text" placeholder="What are you looking for?" />
              <i className="bi bi-search shop-search-icon"></i>
            </div>
            <div className="shop-header-icons">
              <Link to="/wishlist" className="shop-header-icon">
                <i className="bi bi-heart" style={{ color: "black" }}></i>
              </Link>
              <Link to="/cart" className="shop-header-icon cart-icon">
                <i className="bi bi-cart" style={{ color: "black" }}></i>
                <span
                  className="cart-badge"
                  style={{ backgroundColor: "#000740" }}
                >
                  2
                </span>
              </Link>
            </div>
          </div>
        </Row>

        <div
          className="line"
          style={{ height: "1px", backgroundColor: "#ccc", margin: "20px 0" }}
        />

        <div
          style={{
            display: "flex",
            gap: "10px",
            opacity: "0.3",
            fontSize: "12.5px",
          }}
        >
          <span>Home</span>
          <span>/</span>
          <span>Checkout</span>
        </div>

        <h3 className="mt-4">Billing Details</h3>

        <Row>
          <Col xs={12} md={6}>
            <div className="Checkout_container" style={{ padding: "10px" }}>
              <form>
                {[
                  "First Name",
                  "Email Address",
                  "Phone Number",
                  "Company Name",
                  "Country",
                  "Town/City",
                  "Street Address",
                  "Apartment, floor, etc. (optional)",
                ].map((label, index) => (
                  <div key={index} style={{ marginBottom: "32px" }}>
                    <label style={{ display: "block", opacity: "0.3" }}>
                      {label}:
                    </label>
                    <input
                      style={{
                        height: "50px",
                        width: "100%",
                        borderRadius: "4px",
                        backgroundColor: "#f5f5f5",
                        border: "none",
                      }}
                      type="text"
                      name={label.replace(" ", "_").toLowerCase()}
                    />
                  </div>
                ))}

                <div style={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" style={{ marginRight: "8px" }} />
                  <span>
                    Save this information for faster check-out next time
                  </span>
                </div>
              </form>
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div
              className="place-order"
              style={{
                padding: "50px",
                width: "100%",
                top: "371px",
                marginLeft: "32px",
              }}
            >
              <Container className="bg-white">
                <Row>
                  <Col xs={12}>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr className="bg-white">
                            <th  className="bg-white">Item</th>
                            <th className="bg-white">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bg-white">
                              <div 
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={fram1}
                                  style={{
                                    width: "48px",
                                    height: "42px",
                                    marginRight: "16px",
                                  }}
                                  alt="Toyota"
                                />
                                <span>Toyota Services Filter Kits</span>
                              </div>
                            </td>
                            <td className="bg-white text-end">650 AED</td>
                          </tr>
                          <tr>
                            <td className="bg-white">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={hondaspare}
                                  style={{
                                    width: "48px",
                                    height: "42px",
                                    marginRight: "16px",
                                  }}
                                  alt="Honda"
                                />
                                <span>Honda Services Filter Kits</span>
                              </div>
                            </td>
                            <td className="bg-white text-end">1100 AED</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <span>Sub Total:</span>
                      <span>1750 AED</span>
                    </div>
                    <div
                      className="line"
                      style={{
                        height: "0.5px",
                        backgroundColor: "#ccc",
                        margin: "20px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Shipping:</span>
                      <span>25 AED</span>
                    </div>
                    <div
                      className="line"
                      style={{
                        width: "100%",
                        opacity: "50%",
                        height: "0.5px",
                        backgroundColor: "#CCC",
                        margin: "20px 0",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <span>Total:</span>
                      <span>1750 AED</span>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Button
                        style={{
                          width: "100%",
                          height: "56px",
                          borderRadius: "4px",
                          backgroundColor: "#000740",
                          color: "white",
                          fontSize: "16px",
                        }}
                      >
                        Place Order
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>

     <Footer />
    </div>
  );
};

export default Cart;
