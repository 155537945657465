import "./SignInPage.css";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signInAction,
  setCurrentLanguage,
  clearAuthError,
} from "../redux/auth/authActions";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import i18n from "../i18n"; // Import your i18n configuration
import logo from "../assets/images/blue-logo.png"; // Import the image file
import Swal from "sweetalert2";
import { Button, message, Space } from "antd";
import RedirectToHome from "../components/RedirectToHome";
// import { height } from '@mui/system';

const SignInPage = () => {
  // const {continue}
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0] || 'en');
  const [language, setLanguage] = useState(
    localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en"
  );
  const dispatch = useDispatch();
  const { isAuthenticated, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    // Detect and set the default language based on the browser or localStorage
    const detectedLanguage =
      localStorage.getItem("selectedLanguage") ||
      navigator.language.split("-")[0] ||
      "en";
    i18n.changeLanguage(detectedLanguage);
    document.documentElement.lang = detectedLanguage;
  }, [error, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    if (!email) {
      setEmailError(t("email_required"));
      valid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError(t("password_required"));
      valid = false;
    } else {
      setPasswordError("");
    }

    if (valid) {
      const response = await dispatch(signInAction({ email, password }));
      if (response?.code === 200) {
        navigate("/");
      }
    }
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    // localStorage.setItem('selectedLanguage', lang);
    document.documentElement.lang = lang;
    setLanguage(lang);
    dispatch(setCurrentLanguage(lang));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value) {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    if (e.target.value) {
      setPasswordError("");
    }
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "This is a success message",
    });
  };

  return (
    <>
      <RedirectToHome />
      <div className="login-bg">
        <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
          <div className="login-register">
            <div className="text-center mb-5">
              <img src={logo} alt="Logo" width="200" />
            </div>
            <div className="card text-center bg-white rounded-20">
              <div className="card-body p-5">
                <h2 className="mb-0 fw-bold theme-color font-26">
                  {t("login")}
                </h2>
                <p className="fw-bold theme-color font-12">
                  {t("enter_credentials")}
                </p>
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <div className="mb-3 text-start">
                    <label htmlFor="email" className="form-label">
                      {t("email")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder={t("email_user_placeholder")}
                      className={`form-control ${
                        emailError ? "is-invalid" : ""
                      }`}
                      id="email"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {emailError && (
                      <div className="invalid-feedback">{emailError}</div>
                    )}
                  </div>
                  <div className="mb-3 text-start">
                    <label htmlFor="password" className="form-label">
                      {t("password")} <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      placeholder={t("password_placeholder")}
                      className={`form-control ${
                        passwordError ? "is-invalid" : ""
                      }`}
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    {passwordError && (
                      <div className="invalid-feedback">{passwordError}</div>
                    )}
                  </div>
                  <Link
                    className="font-13 text-decoration-none text-dark d-flex justify-content-end"
                    to="/forgotten"
                  >
                    {t("forgot_password")}
                  </Link>
                  <button
                    type="submit"
                    style={{ height: "45px" }}
                    className="btn btn-primary w-100 mt-2 rounded-15 text-uppercase"
                  >
                    {t("login")}
                  </button>
                  {error && (
                    <div className="text-danger mt-2">
                      {typeof error === "string" ? error : t("sign_in_failed")}
                    </div>
                  )}
                  <div className="d-flex justify-content-between mt-3">
                    <Link
                      className="font-13 theme-color"
                      to="/individual-register"
                    >
                      {t("register_individual")}
                    </Link>
                    <Link
                      className="font-13 theme-color"
                      to="/company-register"
                    >
                      {t("register_company")}
                    </Link>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3 text-center">
                    <div className="me-4">
                      <Link
                        to="/track-order"
                        className="btn btn-outline-primary w-100 rounded-10 font-13 text-uppercase"
                      >
                        {t("track_your_order")}
                      </Link>
                    </div>
                    <div>
                      <LanguageSelect
                        selectedLanguage={language}
                        onLanguageChange={handleLanguageChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInPage;
