import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactUs, signOutAction } from "../redux/auth/authActions";
import { Navigate, Link } from "react-router-dom";
import SecondaryHeader from "../components/SecondaryHeader";
import Footer from "../components/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, message, Upload } from "antd";
import InputField from "../components/InputField";
import RedirectToSignin from "../components/RedirectToSignin";
import RedirectToHome from "../components/RedirectToHome";
import PrimaryMobileHeader from "../components/PrimaryMobileHeader";
import SuccessForm from "./SuccessForm";

const ContacyUs = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const [formSubmitted, setFormSubmitted] = useState(false); // To track form submission
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [charCount, setCharCount] = useState(0);
  const charLimit = 200;
  const [refId, setRefId] = useState("");

  const validate = () => {
    let formErrors = {};

    if (!formData.first_name) formErrors.first_name = "First name is required";
    if (!formData.last_name) formErrors.last_name = "Last name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.mobile) formErrors.mobile = "Mobile number is required";

    return formErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "message" && value?.length > charLimit) {
      return; // Prevent exceeding character limit
    }

    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "message") {
      setCharCount(value.length); // Update character count for comments
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      mobile: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    const response = await dispatch(contactUs(formData));

    if (response?.code === 200) {
      message.success("Submitted successfully!");

      setRefId(response?.data?.code);
      setFormSubmitted(true); // Set form as successfully submitted
    } else {
      message.error(response?.message || "Submission failed!");
    }
  };

  const handleSignOut = () => {
    dispatch(signOutAction());
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Define the breakpoint for mobile
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <RedirectToSignin />
      <RedirectToHome />
      {!isMobile ? <SecondaryHeader /> : <PrimaryMobileHeader />}
      <div className="container">
        <div className="tendorsPage mt-4 pb-5">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-10">
              {/* Conditionally render the form or the SuccessForm */}
              {formSubmitted ? (
                <SuccessForm
                  refId={refId}
                  resetForm={() => {
                    console.log("kkkkk");
                    setFormSubmitted(false);
                  }}
                /> // Pass any necessary props to SuccessForm
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-7">
                      <div className="card rounded-10 bg-white border-0">
                        <div className="card-body p-5">
                          <h2 className="mb-4">Get In Touch</h2>
                          <p>
                            Have a question or need more information? Fill out
                            the inquiry form below, and our team will get back
                            to you as soon as possible.
                          </p>
                          <div className="row g-3 mb-4 text-start">
                            <div className="col-12 col-sm-6">
                              <InputField
                                placeholder="First Name *"
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                                customClass={
                                  errors.first_name ? "is-invalid" : ""
                                }
                              />
                              {errors.first_name && (
                                <div className="text-danger font-13">
                                  {errors.first_name}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-sm-6">
                              <InputField
                                placeholder="Last Name *"
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                                customClass={
                                  errors.last_name ? "is-invalid" : ""
                                }
                              />
                              {errors.last_name && (
                                <div className="text-danger font-13">
                                  {errors.last_name}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-sm-6">
                              <InputField
                                placeholder="Email *"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                customClass={errors.email ? "is-invalid" : ""}
                              />
                              {errors.email && (
                                <div className="text-danger font-13">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="text-start">
                                <PhoneInput
                                  country={"ae"}
                                  excludeCountries={["il"]}
                                  name="mobile"
                                  value={formData.mobile}
                                  onChange={handlePhoneChange}
                                  inputClass={`form-control w-100 ${
                                    errors.mobile ? "is-invalid" : ""
                                  }`}
                                  masks={{ ae: ".. ... ...." }}
                                  enableSearch={true}
                                />
                                {errors.mobile && (
                                  <div className="text-danger font-13">
                                    {errors.mobile}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <InputField
                                fieldType="textarea"
                                name="message"
                                value={formData.message}
                                placeholder="Write your comments here..."
                                onChange={handleChange}
                                rows={4}
                                customClass="custom-textarea-comments"
                              />
                              <small className="text-muted">
                                {charCount}/{charLimit} characters
                              </small>
                            </div>
                          </div>

                          <div className="row g-3">
                            <div className="col-12 col-sm-12">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg ps-5 pe-5 font-15 mb-3"
                              >
                                Submit
                              </button>
                              <p className="m-0 text-muted font-15">
                                We usually respond between 30-50 minutes
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-5">
                      <div className="card rounded-10 bg-white h-100 border-0">
                        <div className="card-body p-5 ">
                          <h4 className="mb-4">Contact details</h4>
                          <p className="text-muted font-13">
                           
                          </p>
                          <div className="d-flex justify-content-start align-items-top mb-4">
                            <div className="pe-3">
                              <i className="bi bi-envelope font-20"></i>
                            </div>
                            <div>
                              <h6>Email</h6>
                              <Link
                                className="text-dark font-13"
                                to="mailto:cruisemotorsfzco@gmail.com"
                              >
                                info@cruisemotors.co
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex justify-content-start align-items-top mb-5">
                            <div className="pe-3">
                              <i className="bi bi-telephone font-20"></i>
                            </div>
                            <div>
                              <h6>Phone</h6>
                              <Link className="text-dark font-13" to="">
                                +971 43 960 803
                              </Link>
                            </div>
                          </div>

                          <h4 className="mb-4">Follow us</h4>
                          <div>
                            <Link to="/">
                              <i className="bi bi-facebook theme-color font-25 pe-3"></i>
                            </Link>
                            <Link to="/">
                              <i className="bi bi-instagram theme-color font-25 pe-3"></i>
                            </Link>
                            <Link to="/">
                              <i className="bi bi-linkedin theme-color font-25 pe-3"></i>
                            </Link>
                            <Link to="/">
                              <i className="bi bi-twitter theme-color font-25 pe-3"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContacyUs;
