import React from "react";
import imgPlaceholder from "../assets/images/Frame 894.png";
import { Badge, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "../styles/WishlistCard.css"; // Styling for product card

const WishlistCard = ({
  productData,
  btnText,
  isWishlist,
  removeFromWishlist,
}) => {
  const dispatch = useDispatch();
  const addToCart = (id) => {
  };
  return (
    <div className="product-card">
      <Badge.Ribbon
        text={`${productData?.product?.discount}%`}
        placement="start"
        color="#000740"
      >
        <Card
          hoverable
          cover={
            <div className="product-image-container">
              <img
                className="product-image"
                alt={productData.title}
                src={
                  productData?.product?.images?.length > 0
                    ? `http://dev-api.cruisemotors.co/${productData.product.images[0].image.path}`
                    : imgPlaceholder
                }
              />
              {isWishlist && (
                <button
                  className="delete-button"
                  onClick={() => removeFromWishlist(productData?.product?.id)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              )}
            </div>
          }
        >
          <div className="product-details">
            <div className="product-title">{productData?.product?.name}</div>
            <div className="product-prices">
              <span className="product-price">
                {productData?.product?.price} AED
              </span>
              {/* <span className="product-original-price">
                {productData.originalPrice} AED
              </span> */}
            </div>
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={() => addToCart(productData.id)}
            >
              <i className="bi bi-cart"></i> {btnText}
            </button>
          </div>
        </Card>
      </Badge.Ribbon>
    </div>
  );
};

export default WishlistCard;
